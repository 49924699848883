import axios from "axios";
import { createContext, useReducer, useContext, ReactNode } from "react";
import { customAttributeReducer, initialState } from "reducers/customAttributesReducer";
import { clearActionFailure, clearActionSuccess, convertFiltersToQueryString, generateSortQueryParam, Meta, setFailure, setLoading } from "models/util";
import { ProductAttribute } from "models/product";
import { SortDescriptor } from "@progress/kendo-data-query";

const CustomAttributesContext = createContext(initialState);

interface Props {
  children?: ReactNode
}

interface productCatalogProps {
  catalogId?: string,
  page: number,
  take: number,
  filters?: any
  sort?: SortDescriptor[]
}

export const CustomAttributesProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(customAttributeReducer, initialState);

  const getCustomAttributes = ({ catalogId = undefined, page = 1, take = 20, filters = [], sort = [] }: productCatalogProps) => {
    const f = convertFiltersToQueryString(filters);
    setLoading(dispatch);
    axios.get(`${process.env.REACT_APP_API_URL}/product_catalogs/${catalogId}/attribute_definitions?page=${page}&items_per_page=${take}${f}${generateSortQueryParam(sort)}`)
      .then(res => {
        setCustomAttributes(res.data.data, res.data.meta)
      },
        () => setFailure(dispatch, "Error getting Customer Product List Custom Attributes"))
  }

  const setCustomAttributes = (customAttributes: ProductAttribute[], meta: Meta) => {
    dispatch({
      type: "success",
      results: { customAttributes, metaCustomAttributes: meta },
    });
  };

  const removeCustomAttributeById = async (catalogId: string, customAttibuteId: string) => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/product_catalogs/${catalogId}/attribute_definitions/${customAttibuteId}`);
    dispatch({ type: "actionSuccess", message: "Custom Attribute has been removed!" })
    setTimeout(() => getCustomAttributes({ catalogId: catalogId, page: 1, take: 20 }), 2000);
    setTimeout(() => clearActionSuccess(dispatch), 5000)
  };

  const editCustomAttribute = (p: any, catalogId: string, customAttibuteId: string) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/product_catalogs/${catalogId}/attribute_definitions/${customAttibuteId}`, p)
      .then(res => {
        window.location.pathname = `customer-product-lists/custom-attributes/${catalogId}`;
        dispatch({ type: "resetCustomAttributes" })
        dispatch({ type: "actionSuccess", message: "Custom Attribute has been updated!" })
        setTimeout(() => getCustomAttributes({ catalogId, page: 1, take: 20 }), 2000);
        setTimeout(() => clearActionSuccess(dispatch), 5000)
      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(dispatch), 5000)
          return error;
        })
  }

  const createCustomAttribute = (p: any, catalogId: string) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/product_catalogs/${catalogId}/attribute_definitions`, p)
      .then(res => {
        window.location.pathname = `customer-product-lists/custom-attributes/${catalogId}`;
        dispatch({ type: "resetCustomAttributes" })
        dispatch({ type: "actionSuccess", message: "Custom Attribute has been updated!" })
        setTimeout(() => getCustomAttributes({ catalogId, page: 1, take: 20 }), 2000);
        setTimeout(() => clearActionSuccess(dispatch), 5000)
      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(dispatch), 5000)
          return error;
        })
  }

  const resetCustomAttributes = () => {
    dispatch({
      type: "request"
    })
  }

  const value = {
    isLoading: state.isLoading,
    error: state.error,
    customAttributes: state.customAttributes,
    meta: state.meta,
    metaCustomAttributes: state.metaCustomAttributes,
    isSuccess: state.isSuccess,
    message: state.message,
    getCustomAttributes,
    removeCustomAttributeById,
    setCustomAttributes,
    editCustomAttribute,
    createCustomAttribute,
    resetCustomAttributes,
    setLoading,
    setFailure
  };
  return <CustomAttributesContext.Provider value={value}>{children}</CustomAttributesContext.Provider>;
};

const useCustomAttributes = () => {
  const context = useContext(CustomAttributesContext);

  if (context === undefined) {
    throw new Error("useCustomAttributes must be used within CustomAttributesContext");
  }

  return context;
};

export default useCustomAttributes;
