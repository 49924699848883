import { NavLink } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';

const ViewSupplier = (props: any) => {
  const { supplier } = props;

  return (
    <>
      <div className="card">
        <div className="card-body">
          <label className="form-label">Name</label>
          <p className="mb-0">{supplier?.name}</p>
        </div>
      </div>
      <NavLink to={`/suppliers/edit/${supplier?.id}`} replace={true}>
        <button type="button" className="save-ui btn btn-primary btn-block fw-bolder float-end"><EditIcon fontSize="small" />Edit</button>
      </NavLink>
    </>
  );
}

export default ViewSupplier;
