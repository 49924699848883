import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { NavLink } from "react-router-dom";
import { PropsWithChildren } from "react";

export const ActionColumns = (props: PropsWithChildren<any>) => {
  const navigationAttributes = useTableKeyboardNavigation(props.id);
  const { id, route, onDelete, showEdit = true, deleteButtonText = 'Delete' } = props;
  return (
    <td
      style={props.style} // this applies styles that lock the column at a specific position
      className={props.className} // this adds classes needed for locked columns
      colSpan={props.colSpan}
      role={"gridcell"}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
      }}
      {...navigationAttributes}
    >
      {props?.children}
      {showEdit && <NavLink to={`/${route}/edit/${id}`} replace={true}>
        <button type="button" className="btn btn-sm btn-secondary"><EditIcon fontSize="small" />Edit</button>&nbsp;&nbsp;
      </NavLink>}
      {props.onDelete && <button type="button" onClick={() => props.onDelete(id)} className="btn btn-sm btn-secondary"><DeleteIcon fontSize="small" />{deleteButtonText}</button>}
    </td>
  );
};
