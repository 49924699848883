import { Product } from "models/product";
import { DEFAULT_ERROR, Meta } from "models/util";

export interface ModelListProductsState {
  isLoading: boolean;
  products: Product[];
  meta?: Meta,
  error?: string;
  getModelListProducts?: any
  isSuccess?: boolean
}

export const initialState: ModelListProductsState = {
  isLoading: true,
  products: [],
  meta: { current_page: 1, page_size: 20, total_count: 20 },
};

type Action =
  | { type: 'request' }
  | { type: 'success', results: { products: Product[], meta: Meta } }
  | { type: 'failure', error?: string }
  | { type: 'clearActionFailure' }

export function modelListProductsReducer(state: ModelListProductsState, action: Action): ModelListProductsState {
  switch (action.type) {
    case 'request':
      return { ...state, isLoading: true, error: undefined };
    case 'success':
      return { ...state, isLoading: false, products: action.results.products, meta: action.results.meta };
    case 'failure':
      return { ...state, isLoading: false, products: [], error: action.error || DEFAULT_ERROR };
    case 'clearActionFailure':
      return { ...state, error: undefined }
  }
}
