import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Alert } from "@mui/material";

const LoginPage = () => {
  const { loginWithRedirect, isAuthenticated, isLoading, getAccessTokenSilently, error, logout, } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
      if (error) return;

      if (isAuthenticated) {
        if (localStorage.getItem("auth_token")) {
          axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth_token");
          if (window.location.pathname === "/") {
            navigate("/products", { replace: true })
          }
        } else {
          getAccessTokenSilently().then(token => {
            axios.defaults.headers.common['Authorization'] = "Bearer " + token;
            localStorage.setItem("auth_token", token);
            if (window.location.pathname === "/") {
              navigate("/products", { replace: true })
            }
          })
          return;
        }
      } else {
        if (isLoading) return;
        loginWithRedirect();
      }
  }, [isAuthenticated, isLoading, getAccessTokenSilently])

  return (error ? <>
    <Alert className="m-5" severity="error"><strong>Unathorized: You don't have access.</strong> Please contact your administrator</Alert>
    <button onClick={() => logout()} type="button" className="m-5 btn btn-sm btn-secondary">Logout</button>
  </> :
    <>
      <p>loading...</p></>
  );
}

export default LoginPage;
