import axios from "axios";
import { createContext, useReducer, useContext, ReactNode } from "react";
import { productCatalogReducer, initialState } from "reducers/productCatalogReducer";
import { clearActionFailure, clearActionSuccess, convertFiltersToQueryString, generateSortQueryParam, GetEntitiesProps, Meta, setFailure, setLoading } from "models/util";
import { ProductCatalog } from "models/product_catalog";
import { processDateObjects } from "shared/functions/processDateObjects";

const ProductCatalogContext = createContext(initialState);

interface Props {
  children?: ReactNode
}

export const ProductCatalogProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(productCatalogReducer, initialState);

  const getProductCatalogs = ({ page = 1, take = 20, filters = [], sort = [] }: GetEntitiesProps) => {
    const f = convertFiltersToQueryString(filters);
    setLoading(dispatch);
    axios.get(`${process.env.REACT_APP_API_URL}/product_catalogs?page=${page}&items_per_page=${take}&${f}${generateSortQueryParam(sort)}`)
      .then(res => {
        setProductCatalogs(processDateObjects(res.data.data) as ProductCatalog[], res.data.meta)
      },
        () => setFailure(dispatch, "Error getting Customer Product Lists"))
  }

  const setProductCatalogs = (productCatalogs: ProductCatalog[], meta: Meta) => {
    dispatch({
      type: "success",
      results: { productCatalogs, meta },
    });
  };

  const createProductCatalog = (p: ProductCatalog) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/product_catalogs`, p)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Product Catalog has been created!" })
        setTimeout(() => {
          getProductCatalogs({ page: 1, take: 20 });
        }, 1000);
        setTimeout(() => clearActionSuccess(dispatch), 5000)
      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(dispatch), 5000)
          return error;
        })
  }

  const editProductCatalog = (p: ProductCatalog) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/product_catalogs/${p.id}`, p)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Product Catalog has been updated!" })
        setTimeout(() => getProductCatalogs({ page: 1, take: 20 }), 2000);
        setTimeout(() => clearActionSuccess(dispatch), 5000)
      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(dispatch), 5000)
          return error;
        })
  }

  const removeProductCatalogById = (id: string | undefined) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/product_catalogs/${id}`).then(res => {
      dispatch({ type: "actionSuccess", message: "Product Catalog has been deleted!" })
      setTimeout(() => getProductCatalogs({ page: 1, take: 20 }), 2000);
      setTimeout(() => clearActionSuccess(dispatch), 5000)
    });
  }

  const value = {
    isLoading: state.isLoading,
    error: state.error,
    productCatalogs: state.productCatalogs,
    meta: state.meta,
    isSuccess: state.isSuccess,
    message: state.message,
    createProductCatalog,
    removeProductCatalogById,
    editProductCatalog,
    setProductCatalogs,
    getProductCatalogs,
    setLoading,
    setFailure
  };
  return <ProductCatalogContext.Provider value={value}>{children}</ProductCatalogContext.Provider>;
};

const useProductCatalogs = () => {
  const context = useContext(ProductCatalogContext);

  if (context === undefined) {
    throw new Error("useProductsCatalog must be used within ProductsContext");
  }

  return context;
};

export default useProductCatalogs;
