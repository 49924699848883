import { Category } from "models/category";
import { Meta } from "models/util";

export interface CategoriesState {
  isLoading: boolean;
  categories: Category[];
  meta?: Meta,
  error?: string;
  getCategories?: any;
  editCategory?: any;
  createCategory?: any
  isSuccess?: boolean,
  message?: string,
  clearActionSuccess?: any;
  removeCategoryById?: any;
}

export const initialState: CategoriesState = {
  isLoading: true,
  categories: [],
  meta: { current_page: 1, page_size: 20, total_count: 20 },
};

type Action =
  | { type: 'request' }
  | { type: 'success', results: { categories: Category[], meta: Meta } }
  | { type: 'failure', error: string }
  | { type: 'failure', error?: string }
  | { type: 'actionSuccess', message: string }
  | { type: 'clearActionSuccess' }
  | { type: 'clearActionFailure' };

export function categoriesReducer(state: CategoriesState, action: Action): CategoriesState {
  switch (action.type) {
    case 'request':
      return { ...state, isLoading: true, error: undefined };
    case 'success':
      return { ...state, isLoading: false, categories: action.results.categories, meta: action.results.meta };
    case 'failure':
      return { ...state, isLoading: false, error: action.error };
    case 'actionSuccess':
      return { ...state, isSuccess: true, message: action.message, error: undefined }
    case 'clearActionSuccess':
      return { ...state, isSuccess: false, message: undefined }
    case 'clearActionFailure':
      return { ...state, error: undefined }
  }
}
