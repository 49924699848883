import axios from "axios";
import { PredefinedClass } from "models/predefined_class";
import { createContext, useReducer, useContext, ReactNode } from "react";
import { predefinedClassesReducer, initialState } from "reducers/predefinedClassesReducer";
import { clearActionFailure, clearActionSuccess, convertFiltersToQueryString, Meta, setFailure, setLoading, generateSortQueryParam, GetEntitiesProps } from "models/util";
import { processDateObjects } from "shared/functions/processDateObjects";

const PredefinedClassesContext = createContext(initialState);

interface Props {
  children?: ReactNode
}

export const PredefinedClassesProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(predefinedClassesReducer, initialState);

  const removePredefinedClassById = (id: string | undefined) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/predefined_classes/${id}`).then(res => {
      dispatch({ type: "actionSuccess", message: "Predefined Class has been deleted!" })
      setTimeout(() => getPredefinedClasses({ page: 1, take: 20 }), 2000);
      setTimeout(() => clearActionSuccess(dispatch), 5000)
    });
  }

  const getPredefinedClasses = ({ page = 1, take = 20, filters = [], sort = [] }: GetEntitiesProps) => {
    const f = convertFiltersToQueryString(filters);
    setLoading(dispatch);
    axios.get(`${process.env.REACT_APP_API_URL}/predefined_classes/?page=${page}&items_per_page=${take}${f}${generateSortQueryParam(sort)}`)
      .then(res => {
        setPredefinedClasses(processDateObjects(res.data.data), res.data.meta)
      },
        () => setFailure(dispatch, "Error getting Predefined Classes"))
  }

  const getPredefinedClass = (id: string) => {
    setLoading(dispatch);
    axios.get(`${process.env.REACT_APP_API_URL}/predefined_classes/${id}`)
      .then(res => {
        setPredefinedClasses(processDateObjects([res.data.data]), res.data.meta)
      },
        () => setFailure(dispatch, "Error getting Predefined Class"))
  }


  const editPredefinedClass = (p: PredefinedClass) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/predefined_classes/${p.id}`, p)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Predefined Class has been updated!" })
        setTimeout(() => getPredefinedClasses({ page: 1, take: 20 }), 2000);
        setTimeout(() => clearActionSuccess(dispatch), 5000)
      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(dispatch), 5000)
          return error;
        })
  }

  const createPredefinedClass = (p: PredefinedClass) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/predefined_classes`, p)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Predefined Class has been created!" })
        setTimeout(() => getPredefinedClasses({ page: 1, take: 20 }), 2000);
        setTimeout(() => clearActionSuccess(dispatch), 5000)
      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(dispatch), 5000)
          return error;
        })
  }

  const resetPredefinedClass = (id: string) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/predefined_classes/${id}/reset`)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Predefined Class has been reset!" })
        setTimeout(() => getPredefinedClasses({ page: 1, take: 20 }), 2000);
        setTimeout(() => clearActionSuccess(dispatch), 5000)
      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(dispatch), 5000)
          return error;
        })
  }

  const setPredefinedClasses = (predefinedClasses: PredefinedClass[], meta: Meta) => {
    dispatch({
      type: "success",
      results: { predefinedClasses: predefinedClasses, meta },
    });
  };

  const value = {
    isLoading: state.isLoading,
    error: state.error,
    predefinedClasses: state.predefinedClasses,
    meta: state.meta,
    setPredefinedClasses: setPredefinedClasses,
    getPredefinedClass: getPredefinedClass,
    getPredefinedClasses: getPredefinedClasses,
    editPredefinedClass: editPredefinedClass,
    resetPredefinedClass: resetPredefinedClass,
    removePredefinedClassById: removePredefinedClassById,
    createPredefinedClass: createPredefinedClass
  };
  return <PredefinedClassesContext.Provider value={value}>{children}</PredefinedClassesContext.Provider>;
};

const usePredefinedClasses = () => {
  const context = useContext(PredefinedClassesContext);

  if (context === undefined) {
    throw new Error("usePredefinedClasses must be used within PredefinedClassesContext");
  }

  return context;
};

export default usePredefinedClasses;
