import { Box, Drawer, Modal, Popover } from "@mui/material";
import axios from "axios";
import CommonLayout from "components/layouts/common-layout";
import { ProductsTable } from "components/products/productsTable";
import { ProductProvider } from "context/productContext";
import { FormMode } from "models/util";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';
import ViewProduct from "./viewProduct";
import EditProduct from "./editProduct";
import AddProduct from "./addProduct";
import { BrandCategoriesProvider } from "context/brandCategoriesContext";
import { ModelListsProvider } from "context/modelListsContext";
import { CategoriesProvider } from "context/categoriesContext";
import { BrandsProvider } from "context/brandsContext";
import { SuppliersProvider } from "context/suppliersContext";
import { ClassificationsProvider } from "context/classificationsContext";
import { SubClassificationsProvider } from "context/subClassificationContext";
import { BrandFamiliesProvider } from "context/brandsFamiliesContext";
import { useFileUpload } from "hooks/useFileUpload";
import { DropZone } from "components/shared/dropZone";
import { BootstrapButton } from "components/shared/bootstrapButton";
import { modalStyle } from "components/shared/modalStyles";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DescriptionIcon from '@mui/icons-material/Description';
import UploadIcon from '@mui/icons-material/Upload';

interface ProductProps {
  mode: FormMode
}

const ProductsPage = (props: ProductProps) => {
  const [drawerTitle, setDrawerTitle] = useState<string>("Products")
  let { editId, previewId } = useParams();
  const [product, setProduct] = useState<any>({});
  const [mode, setMode] = useState<FormMode>(props.mode)
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalRefOpen, setIsModalRefOpen] = useState<boolean>(false);
  const [file, setFile] = useState<Blob>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadFile] = useFileUpload();

  useEffect(() => {
    if (editId) {
      setProduct(null);
      setMode(FormMode.Edit);
      getProductById(editId).then((res: any) => {
        setProduct(res.data.data)
      })
    }
  }, [editId]);

  useEffect(() => {
    if (previewId) {
      setMode(FormMode.View);
      getProductById(previewId).then((res: any) => {
        setProduct(res.data.data)
      })
    }
  }, [previewId]);

  useEffect(() => {
    if (mode === FormMode.Create) {
      setDrawerTitle("New Product")
    }
    else if (mode === FormMode.Edit) {
      setDrawerTitle("Edit Product")
    }
    else {
      setDrawerTitle("Product")
    }
  }, [mode]);

  const getProductById = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/products/${id}`)
  }
  const onExit = () => {
    setMode(FormMode.None);
    setProduct(undefined);
    navigate("/products", { replace: true })
  }

  const handleOpen = () => {
    setIsModalOpen(true);
    handlePopClose();
  }
  const handleRefOpen = () => {
    setIsModalRefOpen(true);
    handlePopClose();
  }
  const handleClose = () => {
    setFile(undefined);
    setIsModalOpen(false);
  }
  const handleRefClose = () => {
    setFile(undefined);
    setIsModalRefOpen(false);
  }

  const onFileAdded = (file: any) => {
    setFile(file)
  }

  const handleUpload = (import_type: string) => {
    setIsUploading(true);
    const options = {
      import_type: import_type,
      import_strategies: [],
      context: {}
    }
    uploadFile(file, options).then(() => {
      setIsUploading(false);
    })
  }

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePopClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <CommonLayout>
      {/* Product modal */}
      <div className="alert alert-primary d-flex align-items-center" role="alert">
        <InfoIcon className="primary-col" style={{ marginRight: 10 }} />
        <div>
          <p>
            Important update: BrandBank will transition to using GUIDs instead of UUIDs for product identification. Make sure to adjust your processes accordingly. <br />
            If you have any questions, reach out to BB team.
          </p>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <h2 className="float-start normal-header">
            <ClearOutlinedIcon onClick={handleClose} className="primary-col pointer" style={{ marginTop: "-4px" }} />
            <span style={{ marginLeft: "40px" }}>Add or Edit Products via CSV</span>
          </h2>
          <div className="clearfix"></div>
          <DropZone onFileAdded={onFileAdded} />
          <div className="card my-3">
            <div className="card-body">
              <DescriptionIcon className="primary-col float-left" style={{ fontSize: 30 }} />
              <p className="m-0 float-left middle-par">Example of creating new products</p>
              <a type="button" href={`${process.env.REACT_APP_API_BASE_URL}/imports/create_products.csv`} className="btn btn-sm btn-secondary float-right">Download</a>
            </div>
          </div>
          <div className="card my-3">
            <div className="card-body">
              <DescriptionIcon className="primary-col float-left" style={{ fontSize: 30 }} />
              <p className="m-0 float-left middle-par">Example of updating existing products</p>
              <a type="button" href={`${process.env.REACT_APP_API_BASE_URL}/imports/update_products.csv`} className="btn btn-sm btn-secondary float-right">Download</a>
            </div>
          </div>
          {!isUploading && !!file && <>
            <BootstrapButton variant="contained" onClick={() => handleUpload("products")}>
              <FileUploadIcon fontSize='small' />
              <label>Upload</label>
            </BootstrapButton>
          </>}
          {isUploading && <>
            <BootstrapButton disabled variant="contained" onClick={() => handleUpload("products")}>
              <label>In progress...</label>
            </BootstrapButton>
          </>}

        </Box>
      </Modal>
      {/* References Modal */}
      <Modal
        open={isModalRefOpen}
        onClose={handleRefClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <h2 className="float-start normal-header">
            <ClearOutlinedIcon onClick={handleRefClose} className="primary-col pointer" style={{ marginTop: "-4px" }} />
            <span style={{ marginLeft: "40px" }}>Update Customer References via CSV</span>
          </h2>
          <div className="clearfix"></div>
          <DropZone onFileAdded={onFileAdded} />
          <div className="card my-3">
            <div className="card-body">
              <DescriptionIcon className="primary-col float-left" style={{ fontSize: 30 }} />
              <p className="m-0 float-left middle-par">Example of updating customer refefences</p>
              <a type="button" href={`${process.env.REACT_APP_API_BASE_URL}/imports/customer_references.csv`} className="btn btn-sm btn-secondary float-right">Download</a>
            </div>
          </div>
          {!isUploading && !!file && <>
            <BootstrapButton variant="contained" onClick={() => handleUpload("customer_references")}>
              <FileUploadIcon fontSize='small' />
              <label>Upload</label>
            </BootstrapButton>
          </>}
          {isUploading && <>
            <BootstrapButton disabled variant="contained" onClick={() => handleUpload("customer_references")}>
              <label>In progress...</label>
            </BootstrapButton>
          </>}

        </Box>
      </Modal>
      <h2 className="float-start">Products</h2>
      <button onClick={() => {
        navigate("/products/new", { replace: true })
        setMode(FormMode.Create)
      }} className="btn btn-primary btn-block fw-bolder float-end floating-button-right">
        <AddIcon />New Product
      </button>
      <button aria-describedby={id} onClick={handlePopClick} className="btn btn-secondary btn-block fw-bolder float-end floating-button-right-snd">
        <UploadIcon />Upload CSV
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <section className="csv-menu">
          <p>
            <span onClick={handleOpen} className="nav-link pointer">Add or Edit Products</span>
            <span onClick={handleRefOpen} className="nav-link pointer">Update Customer References</span>
          </p>
        </section>
      </Popover>
      <ProductProvider>
        <BrandCategoriesProvider>
          <CategoriesProvider>
            <BrandsProvider>
              <SuppliersProvider>
                <ClassificationsProvider>
                  <SubClassificationsProvider>
                    <BrandFamiliesProvider>
                      <ModelListsProvider>
                        <div className="clearfix"></div>
                        <ProductsTable />
                        <Drawer
                          PaperProps={{ style: { width: '1200px' } }}
                          anchor={'right'}
                          open={mode !== FormMode.None}
                          onClose={onExit}
                        >
                          <section className="sidebar-entity p-4">
                            <h2 className="float-start">
                              <ArrowBackIcon onClick={onExit} className="primary-col pointer" /><span className="px-2">{drawerTitle}</span>
                            </h2>
                            <div className="clearfix" />
                            {mode === FormMode.View && <ViewProduct product={product} />}
                            {mode === FormMode.Create && <AddProduct onExit={onExit} />}
                            {mode === FormMode.Edit && product && <EditProduct onExit={onExit} product={product} />}
                          </section>
                        </Drawer>
                      </ModelListsProvider>
                    </BrandFamiliesProvider>
                  </SubClassificationsProvider>
                </ClassificationsProvider>
              </SuppliersProvider>
            </BrandsProvider>
          </CategoriesProvider>
        </BrandCategoriesProvider>
      </ProductProvider>
    </CommonLayout >
  )
}

export default ProductsPage;
