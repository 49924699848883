import { Alert, Snackbar } from "@mui/material"

const ErrorToast = (props: any) => (
  <Snackbar open={true} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
    <Alert severity="error" sx={{ width: '100%' }}>
      {props.message}
    </Alert>
  </Snackbar>
)

export default ErrorToast
