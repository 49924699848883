import axios from 'axios';
import { createContext, useReducer, useContext, ReactNode, useEffect } from 'react';
import { authReducer, initialState, AuthState } from 'reducers/authReducer';
import { useAuth0 } from '@auth0/auth0-react';

const AuthContext = createContext<{ state: AuthState; dispatch: React.Dispatch<any> }>({
  state: initialState,
  dispatch: () => undefined,
});

interface Props {
  children?: ReactNode;
}

export const AuthProvider = ({ children }: Props) => {
  const { logout, getAccessTokenSilently } = useAuth0();
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      async (config) => {
        if (localStorage.getItem('auth_token') !== null) {
          try {
            const res = await getAccessTokenSilently();
            localStorage.setItem('auth_token', res);
            config.headers['Authorization'] = `Bearer ${res}`;
          } catch (error) {
            console.error('Error occurred during request interceptor:', error);
          }
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        dispatch({ type: 'success' });
        dispatch({ type: 'resetForbiddenError' });
        return response;
      },
      (error) => {
        try {
          if (error.code === 'ERR_CANCELED') return {};

          if (error.response?.status === 401) {
            localStorage.removeItem('auth_token');
            logout({ logoutParams: { returnTo: window.location.origin } });
            return Promise.reject(error);
          } else if (error.response?.status === 403) {
            console.log('403 error detected');
            dispatch({ type: 'setForbiddenError', message: 'Oops! You don’t have access to this page.' });

            window.location.href = '/403';

            return Promise.resolve({ data: null });
          } else {
            return Promise.reject(error);
          }
        } catch (err) {
          console.error('Error occurred during response interceptor:', err);
          return Promise.reject(error);
        }
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [getAccessTokenSilently, logout, dispatch]);

  const value = {
    state,
    dispatch,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuthentication = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuthentication must be used within AuthProvider');
  }

  return context;
};

export default useAuthentication;
