import CommonLayout from "components/layouts/common-layout";
import { BrandCategoriesTable } from "components/brandCategories/brandCategoriesTable";
import { BrandCategoriesProvider } from "context/brandCategoriesContext";
import { Button, Drawer, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import AddBrandCategory from "./addBrandCategory";
import EditBrandCategory from "./editBrandCategory";
import ViewBrandCategory from "./viewBrandCategory";
import { BrandsProvider } from "context/brandsContext";
import { CategoriesProvider } from "context/categoriesContext";
import { FormMode } from "models/util";

interface BrandCategoriesProps {
  mode: FormMode
}

const BrandCategoriesPage = (props: BrandCategoriesProps) => {
  const [drawerTitle, setDrawerTitle] = useState<string>("Brand Category")
  let { editId, previewId } = useParams();
  const [brandCategory, setBrandCategory] = useState<any>({});
  const [mode, setMode] = useState<FormMode>(props.mode)
  const navigate = useNavigate();

  useEffect(() => {
    if (editId) {
      setBrandCategory(null);
      setMode(FormMode.Edit);
      getBrandCategoryById(editId).then((res: any) => {
        setBrandCategory(res.data.data)
      })
    }
  }, [editId]);

  useEffect(() => {
    if (previewId) {
      setMode(FormMode.View);
      getBrandCategoryById(previewId).then((res: any) => {
        setBrandCategory(res.data.data)
      })
    }
  }, [previewId]);

  useEffect(() => {
    if (mode === FormMode.Create) {
      setDrawerTitle("New Brand Category")
    }
    else if (mode === FormMode.Edit) {
      setDrawerTitle("Edit Brand Category")
    }
    else {
      setDrawerTitle("Brand Category")
    }
  }, [mode]);

  const getBrandCategoryById = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/brand_categories/${id}`)
  }

  const onExit = () => {
    setMode(FormMode.None);
    setBrandCategory(undefined);
    navigate("/brand-categories", { replace: true })
  }
  return (
    <CommonLayout>
      <h2 className="float-start">Brand Categories</h2>
      <button onClick={() => {
        setMode(FormMode.Create)
        navigate("/brand-categories/new", { replace: true })
      }} className="btn btn-primary btn-block fw-bolder float-end">
        <AddIcon />New Brand Category
      </button>
      <BrandCategoriesProvider>
        <CategoriesProvider>
          <BrandsProvider>
            <div className="clearfix"></div>
            <BrandCategoriesTable />
            <Drawer
              PaperProps={{ style: { width: '600px' } }}
              anchor={'right'}
              open={mode !== FormMode.None}
              onClose={onExit}
            >
              <section className="sidebar-entity p-4">
                <h2 className="float-start">
                  <ArrowBackIcon onClick={onExit} className="primary-col pointer" /><span className="px-2">{drawerTitle}</span>
                </h2>
                <div className="clearfix" />
                {mode === FormMode.View && <ViewBrandCategory brandCategory={brandCategory} />}
                {mode === FormMode.Create && <AddBrandCategory onExit={onExit} />}
                {mode === FormMode.Edit && brandCategory && <EditBrandCategory onExit={onExit} brandCategory={brandCategory} />}
              </section>
            </Drawer>
          </BrandsProvider>
        </CategoriesProvider>
      </BrandCategoriesProvider>
    </CommonLayout>

  )
}

export default BrandCategoriesPage;
