import { ProductAttribute } from "../../models/product";
import { EMPTY_CUSTOM_ATTRIBUTE_VALUE } from "../constants/constants";

export function displayAttributeValue(value: ProductAttribute["value"]) {
  if (Array.isArray(value)) {
    return value.join(", ");
  } else {
    return value || EMPTY_CUSTOM_ATTRIBUTE_VALUE;
  }
}
