import axios from "axios";
import { useNavigate } from "react-router-dom";

export const useFileUpload = () => {
  const navigate = useNavigate();
  const uploadFile = async (file: any, options: any) => {
    let formData = new FormData();
    formData.append("file", file);
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/uploads`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    await axios.post(`${process.env.REACT_APP_API_URL}/imports`, {
      "file_id": res.data.data.id,
      ...options
    });
    navigate("/imports", { replace: true })
  }

  return [uploadFile];
};
