import axios from "axios";
import { BrandFamily } from "models/brand_family";
import { createContext, useReducer, useContext, ReactNode } from "react";
import { brandFamiliesReducer, initialState } from "reducers/brandFamiliesReducer";
import { convertFiltersToQueryString, Meta, generateSortQueryParam, GetEntitiesProps } from "models/util";
import { processDateObjects } from "shared/functions/processDateObjects";
import { useNavigate } from "react-router-dom";

const BrandFamiliesContext = createContext(initialState);

interface Props {
  children?: ReactNode
}

export const BrandFamiliesProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(brandFamiliesReducer, initialState);
  const navigate = useNavigate();

  const removeBrandFamilyById = (id: string | undefined) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/brand_families/${id}`).then(res => {
      dispatch({ type: "actionSuccess", message: "Brand Family has been deleted!" })
      setTimeout(() => getBrandFamilies({ page: 1, take: 20 }), 2000);
      setTimeout(() => clearActionSuccess(), 5000)
    });
  }

  const getBrandFamilies = ({ page = 1, take = 20, filters = [], sort = [] }: GetEntitiesProps) => {
    setLoading();
    const f = convertFiltersToQueryString(filters);
    axios.get(`${process.env.REACT_APP_API_URL}/brand_families?page=${page}&items_per_page=${take}${f}${generateSortQueryParam(sort)}`)
      .then(res => {
        setBrandFamilies(processDateObjects(res.data.data), res.data.meta)
      },
        () => setFailure("Error getting brand_families"))
  }

  const setBrandFamilies = (brandFamilies: BrandFamily[], meta: Meta) => {
    dispatch({
      type: "success",
      results: { brandFamilies: brandFamilies, meta },
    });
  };

  const createBrandFamily = (brandFamily: BrandFamily) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/brand_families`, brandFamily)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "BrandFamily has been created!" })
        getBrandFamilies({ page: 1, take: 20 });
        navigate("/suppliers", { replace: true })
        setTimeout(() => clearActionSuccess(), 5000)

      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(), 5000)
          return error;
        })
  }

  const editBrandFamily = (brandFamily: BrandFamily) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/brand_families/${brandFamily.id}`, brandFamily)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Brand family has been updated!" })
        getBrandFamilies({ page: 1, take: 20 });
        navigate("/suppliers", { replace: true })
        setTimeout(() => clearActionSuccess(), 5000)

      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(), 5000)
          return error;
        })
  }

  const clearActionSuccess = () => {
    dispatch({ type: "clearActionSuccess" })
  }
  const clearActionFailure = () => {
    dispatch({ type: "clearActionFailure" })
  }


  const setLoading = () => {
    dispatch({
      type: "request",
    });
  }

  const setFailure = (errorMessage: string) => {
    dispatch({
      type: "failure",
      error: errorMessage
    });
  }

  const value = {
    isLoading: state.isLoading,
    error: state.error,
    brandFamilies: state.brandFamilies,
    meta: state.meta,
    setBrandFamilies: setBrandFamilies,
    getBrandFamilies: getBrandFamilies,
    editBrandFamily: editBrandFamily,
    createBrandFamily: createBrandFamily,
    removeBrandFamilyById: removeBrandFamilyById,
    setLoading,
    setFailure,
    isSuccess: state.isSuccess,
    message: state.message,

  };
  return <BrandFamiliesContext.Provider value={value}>{children}</BrandFamiliesContext.Provider>;
};

const useBrandFamilies = () => {
  const context = useContext(BrandFamiliesContext);

  if (context === undefined) {
    throw new Error("useBrandFamilies must be used within BrandFamiliesContext");
  }

  return context;
};

export default useBrandFamilies;
