import { useState, useEffect } from 'react';
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent, GridFilterChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import '@progress/kendo-theme-default/dist/all.css';
import { gridSettings, initialSort } from "configs/gridSettings";
import useProducts from 'context/productContext';
import { FullPageLoader } from 'components/shared/fullPageLoader';
import { initialDataState } from 'configs/initialDataState';
import { NavLink, useSearchParams } from 'react-router-dom';
import ErrorToast from 'components/toaster/errorToastr';
import Toast from 'components/toaster/toastr';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { useSearchPagination } from 'components/hooks/useSearchPagination';
import { cloneElement } from 'react';
import { packshotModalStyle } from "components/shared/modalStyles";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Box, Modal } from "@mui/material";
import { BootstrapButton } from "components/shared/bootstrapButton";
import { PackshotsConfirmationModal } from 'components/productPackshots/packshotsConfirmationModal';
import { PackshotsCroppingModal } from 'components/productPackshots/packshotsCroppingModal';
import { LabelCell } from 'components/grid/label-cell';
import { ActionColumns } from 'components/grid/action-column';

export const ProductPackshotsTable = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);

  const [dataState, setDataState] = useState(initialDataState);
  const { products, meta, getProducts, makePhotoPrimary, deleteAllPhotos, deletePhoto, isSuccess, message, error, updatePhoto } = useProducts();
  const [filter, setFilter] = useState<CompositeFilterDescriptor>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sort, setSort] = useState(initialSort);
  const [dragOverRowId, setDragOverRowId] = useState('');
  const [uploadingRowId, setUploadingRowId] = useState('');
  const [searchPageNumber, searchPageSize] = useSearchPagination();

  const [isModalPackshotOpen, setIsModalPackshotOpen] = useState<boolean>(false);
  const [currentPhoto, setCurrentPhoto] = useState<any>(null);
  const [currentProduct, setCurrentProduct] = useState<any>(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const [uploadingFiles, setUploadingFiles] = useState<any>(null);
  const [croppingModalOpen, setCroppingModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState<any>(null);

  useEffect(() => {
    const d = { ...dataState };
    d.take = searchPageSize;
    d.skip = (searchPageNumber - 1) * searchPageSize;
    d.isInitial = false;
    setDataState(d);
  }, []);

  useEffect(() => {
    if (dataState.isInitial) return;
    const page = dataState.skip / dataState.take + 1;
    const take = String(dataState.take);
    setSearchParams(`?${new URLSearchParams({ pageNumber: String(page), pageSize: take })}`);
    getProducts({ page: page, take: dataState.take, filters: filter?.filters, sort: sort });
  }, [dataState]);

  const filterChange = (event: GridFilterChangeEvent) => {
    setFilter(event.filter);
  };

  useEffect(() => {
    if (filter === undefined) return;
    const getData = setTimeout(() => {
      const page = dataState.skip / dataState.take + 1;
      getProducts({ page: page, take: dataState.take, filters: filter?.filters, sort: sort });
    }, 500);

    return () => clearTimeout(getData);
  }, [filter]);

  const sortChanged = (sort: SortDescriptor[]) => {
    const page = dataState.skip / dataState.take + 1;
    getProducts({ page: page, take: dataState.take, filters: filter?.filters, sort: sort });
  };

  const handleDragOver = (event: any, rowId: any) => {
    setDragOverRowId(rowId);
    event.preventDefault(); // Necessary for the drop event to fire
  };

  const handleDrop = (event: any, productId: string) => {
    setDragOverRowId('');
    event.preventDefault();
    const files = event.dataTransfer.files;
    setUploadingFiles(files);
    let product = products.find((product: any) => product.id === productId);
    setCurrentProduct(product);
    setIsConfirmationModalOpen(true);
  };

  const handleDragEnter = (rowId: string) => {
    setDragOverRowId(rowId);
  };

  const handleDragLeave = () => {
    setDragOverRowId('');
  };

  const dropRowRender = (trElement: any, o: any) => {
    const dataItem = o.dataItem;
    const isDragOver = dragOverRowId === dataItem.id;
    const isUploading = uploadingRowId === dataItem.id;

    const trProps = {
      ...trElement.props,
      onDrop: (event: React.DragEvent<HTMLTableRowElement>) => handleDrop(event, dataItem.id),
      onDragEnter: () => handleDragEnter(dataItem.id),
      onDragOver: (event: any) => handleDragOver(event, dataItem.id),
      onDragLeave: handleDragLeave,
      style: isDragOver ? { backgroundColor: '#d4e9ff !important' } : isUploading ? { backgroundColor: '#ffc0cb !important' } : {},
      className: isDragOver ? 'drag-over' : isUploading ? 'uploading' : '',
      children: trElement.props.children
    };

    return cloneElement(trElement, { ...trProps });
  };

  const openPackshotImage = (photo: any, product: any) => {
    setCurrentPhoto(photo);
    setCurrentProduct(product);
    setIsModalPackshotOpen(true);
  };

  const handlePackshotModalClose = () => {
    setIsModalPackshotOpen(false);
  };

  const handleMakeMain = () => {
    makePhotoPrimary(currentProduct.id, currentPhoto.id);
    setIsModalPackshotOpen(false);
  };

  const openCropModal = (photo: any, product: any, index: number) => {
    setCurrentPhoto(photo);
    setCurrentProduct(product);
    const proxy_url = photo?.url.replace("redirect", "proxy");
    setCurrentImage(proxy_url);
    setCurrentImageIndex(index);
    setCroppingModalOpen(true);
  };

  const handleCropConfirm = async (croppedImage: Blob) => {
    if (currentProduct && currentPhoto) {
      const formData = new FormData();
      formData.append('photo', croppedImage, currentPhoto.name);
      setCurrentPhoto({ ...currentPhoto, url: URL.createObjectURL(croppedImage) });
      try {
        await updatePhoto(currentProduct.id, currentPhoto.id, formData);
        setCroppingModalOpen(false);
      } catch (err) {
        console.error("Failed to update photo:", err);
      }
    }
  };

  const handleConfirmationModalClose = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleDeletePhoto = () => {
    if (currentPhoto) {
      deletePhoto(currentPhoto.id)
        .then(() => {
          setIsModalPackshotOpen(false);
        });
    }
  };

  return (
    <div>
      <PackshotsConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={handleConfirmationModalClose}
        productId={currentProduct?.id}
        uploadingFiles={uploadingFiles}
        updatePhoto={updatePhoto}
      />
      <PackshotsCroppingModal
        isOpen={croppingModalOpen}
        onClose={() => setCroppingModalOpen(false)}
        image={currentImage}
        onCropConfirm={handleCropConfirm}
        currentImageIndex={currentImageIndex}
        selectedFiles={selectedFiles}
        currentPhoto={currentPhoto}
        currentProduct={currentProduct}
        updatePhoto={updatePhoto}
        setSelectedFiles={setSelectedFiles}
        closeCroppingModal={() => setCroppingModalOpen(false)}
      />
      <Modal open={isModalPackshotOpen} onClose={handlePackshotModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={packshotModalStyle}>
          <h2 className="float-start normal-header">
            <ClearOutlinedIcon onClick={handlePackshotModalClose} className="primary-col pointer" style={{ marginTop: "-4px" }} />
            <span style={{ marginLeft: "10px" }}>Packshot for {currentProduct?.full_name}</span>
          </h2>
          <div className="clearfix"></div>
          <div className="card my-3">
            <div className="card-body">
              <img src={currentPhoto?.url} className="img-fluid" alt="Packshot" />
            </div>
          </div>
          {!currentPhoto?.main &&
            <button className="btn btn-primary btn-block fw-bolder m-l-15" onClick={handleMakeMain}>
              <label>Make main</label>
            </button>
          }
          <button className="btn btn-secondary btn-block fw-bolder m-l-15" onClick={() => {
            const index = currentProduct.photos.findIndex((photo: any) => photo.id === currentPhoto.id);
            openCropModal(currentPhoto, currentProduct, index);
          }}
          >
            Crop
          </button>
          <button className="btn btn-danger btn-block fw-bolder m-l-15" onClick={handleDeletePhoto}>
            <label>Delete</label>
          </button>
        </Box>
      </Modal>

      {isSuccess && <Toast message={message} />}
      {error && <ErrorToast message={error} />}
      {products && meta ? (
        <Grid
          filter={filter}
          onFilterChange={filterChange}
          sort={sort}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
            sortChanged(e.sort);
          }}
          {...gridSettings}
          data={products}
          total={meta.total_count}
          pageSize={dataState.take}
          onDataStateChange={(e: GridDataStateChangeEvent) => {
            setDataState(e.dataState as any);
          }}
          skip={meta.page_size * meta.current_page - meta.page_size}
          rowRender={dropRowRender}
        >
          <GridColumn
            sortable={false}
            field="photo"
            title="Main photo"
            width="90px"
            filterable={false}
            cell={(o: GridCellProps) => (
              <td className="upload-column-area">
                {o.dataItem.photos
                  .filter((photo: any) => photo.main)
                  .map((photo: any, index: number) => (
                    <img
                      key={index}
                      src={photo.url}
                      className="packshots-list-thumbnail"
                      onClick={() => openPackshotImage(photo, o.dataItem)}
                    />
                  ))}
              </td>
            )}
          />
          <GridColumn
            sortable={false}
            field="photo"
            title="Additional photos"
            filterable={false}
            cell={(o: GridCellProps) => (
              <td className="upload-column-area">
                {o.dataItem.photos
                  .filter((photo: any) => !photo.main)
                  .map((photo: any, index: number) => (
                    <img
                      key={index}
                      src={photo.url}
                      className="packshots-list-thumbnail"
                      onClick={() => openPackshotImage(photo, o.dataItem)}
                    />
                  ))}
              </td>
            )}
          />
          <GridColumn sortable={false} field="id" title="UUID" width="110" />
          <GridColumn field="full_name" title="Full Name" width="250" cell={(o: GridCellProps) => (
            <td>
              <NavLink to={`/products/${o.dataItem.id}`} replace={true} target="_blank">
                {o.dataItem.full_name}
              </NavLink>
            </td>
          )} />
          <GridColumn field="labels" title="Labels" cell={LabelCell} />
          <GridColumn field="ean" width="100%" title="EAN" sortable={false} />
          <GridColumn field="upc" width="100%" title="UPC" sortable={false} />
          <GridColumn field="actions" sortable={false} title="Actions" filterable={false} width="200px" cell={o => (
            <ActionColumns id={o.dataItem.id} route="products" showEdit={false} deleteButtonText='Delete all photos' onDelete={(id: string) => { deleteAllPhotos(id) }}
            />
          )}
          />
        </Grid>
      ) : (
        <FullPageLoader />
      )}
    </div>
  );
};
