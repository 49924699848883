import CommonLayout from "components/layouts/common-layout";
import { SubClassificationsTable } from "components/subClassifications/subClasificationsTable";
import { SubClassificationsProvider } from "context/subClassificationContext";
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import AddSubClassifications from "./addSubClassification";
import EditAddSubClassifications from "./editSubClassification";
import ViewSubClassifications from "./viewSubClassification";
import { Drawer } from "@mui/material";
import { FormMode } from "models/util";

interface SubClassificationsProps {
  mode: FormMode
}

const SubClassificationsPage = (props: SubClassificationsProps) => {
  const [drawerTitle, setDrawerTitle] = useState<string>("Subclassification")
  let { editId, previewId } = useParams();
  const [subClassification, setSubClassification] = useState<any>({});
  const [mode, setMode] = useState<FormMode>(props.mode)
  const navigate = useNavigate();


  useEffect(() => {
    if (editId) {
      setSubClassification(null);
      setMode(FormMode.Edit);
      getSubclassificationById(editId).then((res: any) => {
        setSubClassification(res.data.data)
      })
    }
  }, [editId])

  useEffect(() => {
    if (previewId) {
      setMode(FormMode.View);
      getSubclassificationById(previewId).then((res: any) => {
        setSubClassification(res.data.data)
      })
    }
  }, [previewId])

  useEffect(() => {
    if (mode === FormMode.Create) {
      setDrawerTitle("New Subclassification")
    }
    else if (mode === FormMode.Edit) {
      setDrawerTitle("Edit Subclassification")
    }
    else {
      setDrawerTitle("Subclassification")
    }
  }, [mode])

  const getSubclassificationById = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/subclassifications/${id}`)
  }

  const onExit = () => {
    setMode(FormMode.None);
    setSubClassification(null);
    navigate("/subclassifications", { replace: true })
  }

  return (
    <CommonLayout>
      <h2 className="float-start">Subclassifications</h2>
      <button onClick={() => {
        navigate("/subclassifications/new", { replace: true })
        setMode(FormMode.Create)
      }} className="btn btn-primary btn-block fw-bolder float-end">
        <AddIcon />New Subclassification
      </button>
      <SubClassificationsProvider>
        <div className="clearfix"></div>
        <SubClassificationsTable />
        <Drawer
          PaperProps={{ style: { width: '600px' } }}
          anchor={'right'}
          open={mode !== FormMode.None}
          onClose={onExit}
        >
          <section className="sidebar-entity p-4">
            <h2 className="float-start">
              <ArrowBackIcon onClick={onExit} className="primary-col pointer" /><span className="px-2">{drawerTitle}</span>
            </h2>
            <div className="clearfix" />
            {mode === FormMode.View && <ViewSubClassifications subClassification={subClassification} />}
            {mode === FormMode.Create && <AddSubClassifications onExit={onExit} />}
            {mode === FormMode.Edit && subClassification && <EditAddSubClassifications onExit={onExit} subClassification={subClassification} />}
          </section>
        </Drawer>

      </SubClassificationsProvider>
    </CommonLayout>
  )
}

export default SubClassificationsPage;
