import { Chip } from "@mui/material";
import { NavLink } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';

const ViewLabels = (props: any) => {
  const { label } = props;

  return (
    <>
      <div className="card">
        <div className="card-body">
          <label className="form-label">Id</label>
          <p className="mb-0">{label?.id}</p>
        </div>
        <div className="card-body">
          <label className="form-label">Name</label>
          <p className="mb-0">{label?.name}</p>
        </div>
      </div>
      <div className="card my-3">
        <div className="card-body">
          <label className="form-label">Entity type</label>
          <p className="mb-0">{label?.entity_type}</p>
        </div>
        <div className="card-body">
          <label className="form-label">Entity name</label>
          <p className="mb-0">{label?.entity_name}</p>
        </div>
      </div>

      <div className="card my-3">
        <div className="card-body">
          <label className="form-label">Tags</label>
          {label?.tag_list?.map((t: string) => <>
            <Chip label={t} /> &nbsp;
          </>)}

        </div>
      </div>
      <NavLink to={`/labels/edit/${label?.id}`} replace={true}>
        <button type="button" className="save-ui btn btn-primary btn-block fw-bolder float-end"><EditIcon fontSize="small" />Edit</button>
      </NavLink>
    </>
  );
}

export default ViewLabels;
