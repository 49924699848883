import { Label } from "models/label";
import { DEFAULT_ERROR, Meta } from "models/util";

export interface PredefinedClassLabelsState {
  isLoading: boolean;
  labels: Label[];
  meta?: Meta,
  error?: string;
  getPredefinedClassLabels?: any
  isSuccess?: boolean
}

export const initialState: PredefinedClassLabelsState = {
  isLoading: true,
  labels: [],
  meta: { current_page: 1, page_size: 20, total_count: 20 },
};

type Action =
  | { type: 'request' }
  | { type: 'success', results: { labels: Label[], meta: Meta } }
  | { type: 'failure', error?: string }
  | { type: 'clearActionFailure' }

export function predefinedClassLabelsReducer(state: PredefinedClassLabelsState, action: Action): PredefinedClassLabelsState {
  switch (action.type) {
    case 'request':
      return { ...state, isLoading: true, error: undefined };
    case 'success':
      return { ...state, isLoading: false, labels: action.results.labels, meta: action.results.meta };
    case 'failure':
      return { ...state, isLoading: false, labels: [], error: action.error || DEFAULT_ERROR };
    case 'clearActionFailure':
      return { ...state, error: undefined }
  }
}
