import { useState } from "react";
import { useForm } from "react-hook-form";
import { SaveButton } from "components/shared/saveButton";
import useProductCatalogs from "context/productCatalogContext";
import LockIcon from '@mui/icons-material/Lock';

const EditProductCatalog = (props: any) => {
  const { editProductCatalog } = useProductCatalogs();
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: props.product.name || "",
      key: props.product.key || "",
      id: props.product.id || ""
    }
  });

  return (
    <form
      className={errors.name ? "was-validated" : "needs-validation"}
      onSubmit={handleSubmit((data) => {
        editProductCatalog(data).then((error: any) => {
          if (!error) {
            props.onExit()
          }
        }
        )
      })}
      noValidate
    >
      <div className="card">
        <div className="card-body">
          <label className="form-label">Name</label>
          <input className="form-control"
            {...register("name", { required: true })}
            required
          />
          {errors.name && <div className="invalid-feedback">
            <p className="warning-icon">This field is required.</p>
          </div>}
        </div>
        <div className="card-body">
          <label className="form-label">Key&nbsp; <LockIcon fontSize="inherit" /></label>
          <input className="form-control"
            {...register("key", { required: true, disabled: true })}
            required
          />
        </div>
      </div>

      <SaveButton />
    </form >
  );
}

export default EditProductCatalog;
