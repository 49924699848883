import { SubClassification } from "models/sub_classification";
import { DEFAULT_ERROR, Meta } from "models/util";

export interface SubClassificationState {
  isLoading: boolean;
  subClassifications: SubClassification[];
  meta?: Meta,
  error?: string;
  getSubClassifications?: any
  editSubClassifications?: any;
  createSubClassifications?: any;
  isSuccess?: boolean,
  message?: string,
  clearActionSuccess?: any
  removeSubclassificationById?: any;
}

export const initialState: SubClassificationState = {
  isLoading: true,
  subClassifications: [],
  meta: { current_page: 1, page_size: 20, total_count: 20 },
};

type Action =
  | { type: 'request' }
  | { type: 'success', results: { subClassifications: SubClassification[], meta: Meta } }
  | { type: 'failure', error?: string }
  | { type: 'actionSuccess', message: string }
  | { type: 'clearActionSuccess' }
  | { type: 'clearActionFailure' };

export function subClassificationsReducer(state: SubClassificationState, action: Action): SubClassificationState {
  switch (action.type) {
    case 'request':
      return { ...state, isLoading: true, error: undefined };
    case 'success':
      return { ...state, isLoading: false, subClassifications: action.results.subClassifications, meta: action.results.meta };
    case 'failure':
      return { ...state, isLoading: false, error: action.error || DEFAULT_ERROR };
    case 'actionSuccess':
      return { ...state, isSuccess: true, message: action.message, error: undefined }
    case 'clearActionSuccess':
      return { ...state, isSuccess: false, message: undefined }
    case 'clearActionFailure':
      return { ...state, error: undefined }
  }
}
