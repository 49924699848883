import { useState } from "react";
import { TagsDisplayProps } from "./tagsProps";
import { Tag } from "models/tag";
import Chip from "@mui/material/Chip";
import DeleteIcon from '@mui/icons-material/Delete';
import SWDialog from 'components/shared/dialog';
import axios from "axios";
const uuid = require("uuid");

export const TagsDisplay = (props: TagsDisplayProps) => {

  const { displayedTags, onDeleted, selectedTags } = props;
  const [deleteId, setDeleteId] = useState<string>("");
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const handleDelete = () => {
    axios.delete(`${process.env.REACT_APP_API_URL}/tags/${deleteId}`).then((res) => {
      setIsDialogOpen(false);
      setDeleteId("")
      onDeleted();
    }
    )
  }
  const getChipClass = (name: string) => {
    return selectedTags?.includes(name) ? "primary" : "default";
  }

  return (<>
    <div key={uuid.v4()}>
      {displayedTags?.map((t: Tag) =>
        <Chip
          key={uuid.v4()}
          color={getChipClass(t.id) as any}
          className="chip-tag"
          size="small"
          onClick={() => {
            props.onSelectionChange(t.name);
          }}
          onDelete={() => {
            setDeleteId(t.id);
            setIsDialogOpen(true);
          }}
          deleteIcon={<DeleteIcon style={{ "fontSize": "19px" }} />} label={<>{t.name}
            <span className="badge rounded-pill badge-light">
              {t.taggings_count}
            </span>
            <span className="badge-separator">|</span></>} />
      )}
      <SWDialog
        open={isDialogOpen}
        text="Are you sure that you want to delete this tag?"
        confirmText="Delete"
        onConfirm={() => handleDelete()}
        onClose={() => setIsDialogOpen(false)}
      />
    </div>
  </>)
}
