import axios from "axios";
import { Supplier } from "models/supplier";
import { createContext, useReducer, useContext, ReactNode } from "react";
import { supplierReducer, initialState } from "reducers/suppliersReducer";
import Moment from 'moment';
import { convertFiltersToQueryString, Meta, generateSortQueryParam, GetEntitiesProps } from "models/util";
import { processDateObjects } from "shared/functions/processDateObjects";
import { useNavigate } from "react-router-dom";

const SuppliersContext = createContext(initialState);

interface Props {
  children?: ReactNode
}

export const SuppliersProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(supplierReducer, initialState);

  const removeSupplierById = (id: string | undefined) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/suppliers/${id}`).then(res => {
      dispatch({ type: "actionSuccess", message: "Supplier has been deleted!" })
      setTimeout(() => getSuppliers({ page: 1, take: 20 }), 2000);
      setTimeout(() => clearActionSuccess(), 5000)
    });
  }

  const getSuppliers = ({ page = 1, take = 20, filters = [], sort = [] }: GetEntitiesProps) => {
    const f = convertFiltersToQueryString(filters);
    setLoading();
    axios.get(`${process.env.REACT_APP_API_URL}/suppliers/?page=${page}&items_per_page=${take}${f}${generateSortQueryParam(sort)}`)
      .then(res => {
        setSuppliers(processDateObjects(res.data.data), res.data.meta)
      },
        () => setFailure("Error getting suppliers"))
  }

  const getSupplierById = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/suppliers/${id}`)
      .then(res => {
        return res.data
      },
        () => setFailure("Error getting suppliers"))
  }

  const createSupplier = (supplier: Supplier) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/suppliers`, supplier)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Supplier has been created!" })
        getSuppliers({ page: 1, take: 20 });
        navigate("/suppliers", { replace: true })
        setTimeout(() => clearActionSuccess(), 5000)

      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(), 5000)
          return error;
        })
  }

  const editSupplier = (supplier: Supplier) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/suppliers/${supplier.id}`, supplier)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Supplier has been updated!" })
        getSuppliers({ page: 1, take: 20 });
        navigate("/suppliers", { replace: true })
        setTimeout(() => clearActionSuccess(), 5000)

      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(), 5000)
          return error;
        })
  }

  const clearActionSuccess = () => {
    dispatch({ type: "clearActionSuccess" })
  }
  const clearActionFailure = () => {
    dispatch({ type: "clearActionFailure" })
  }

  const setSuppliers = (suppliers: Supplier[], meta: Meta) => {
    dispatch({
      type: "success",
      results: { suppliers: suppliers, meta },
    });
  };

  const setLoading = () => {
    dispatch({
      type: "request",
    });
  }

  const setFailure = (errorMessage: string) => {
    dispatch({
      type: "failure",
      error: errorMessage
    });
  }

  const value = {
    isLoading: state.isLoading,
    error: state.error,
    suppliers: state.suppliers,
    meta: state.meta,
    setSuppliers: setSuppliers,
    getSuppliers: getSuppliers,
    getSupplierById: getSupplierById,
    createSupplier: createSupplier,
    editSupplier: editSupplier,
    removeSupplierById: removeSupplierById,
    setLoading,
    setFailure,
    isSuccess: state.isSuccess,
    message: state.message,
  };
  return <SuppliersContext.Provider value={value}>{children}</SuppliersContext.Provider>;
};

const useSuppliers = () => {
  const context = useContext(SuppliersContext);

  if (context === undefined) {
    throw new Error("useSuppliers must be used within SuppliersContext");
  }

  return context;
};

export default useSuppliers;
