import { Label } from "models/label";
import { DEFAULT_ERROR, Meta } from "models/util";

export interface LabelState {
  isLoading: boolean;
  labels: Label[];
  meta?: Meta,
  error?: string;
  getLabels?: any
  createLabels?: any,
  editLabel?: any;
  isSuccess?: boolean,
  message?: string,
  clearActionSuccess?: any
}

export const initialState: LabelState = {
  isLoading: true,
  labels: [],
  meta: { current_page: 1, page_size: 20, total_count: 0 },
};

type Action =
  | { type: 'request' }
  | { type: 'success', results: { labels: Label[], meta: Meta } }
  | { type: 'failure', error?: string }
  | { type: 'actionSuccess', message: string }
  | { type: 'clearActionSuccess' }
  | { type: 'clearActionFailure' };

export function labelsReducer(state: LabelState, action: Action): LabelState {
  switch (action.type) {
    case 'request':
      return { ...state, isLoading: true, error: undefined };
    case 'success':
      return { ...state, isLoading: false, labels: action.results.labels, meta: action.results.meta };
    case 'failure':
      return { ...state, isLoading: false, error: action.error || DEFAULT_ERROR };
    case 'actionSuccess':
      return { ...state, isSuccess: true, message: action.message, error: undefined }
    case 'clearActionSuccess':
      return { ...state, isSuccess: false, message: undefined }
    case 'clearActionFailure':
      return { ...state, error: undefined }
  }
}
