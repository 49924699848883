import { NavLink, useParams } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import { ShowInstanceComponent } from "./showInstance";
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import { NOVALUE } from "shared/constants/constants";
import { ProductAttribute } from "models/product";
import { displayAttributeValue } from "shared/functions/displayAttributeValue";

const ViewProductInstance = (props: any) => {
  const { product } = props;
  let { previewId, viewProductInstanceId } = useParams();

  return (props.product &&
    <div className="product-split row">
      <div className="product-form col-6">
        <div className="card">
          <div className="card-body">
            <ShowInstanceComponent label={"Name"} instanceProp={product?.instance?.name} productProp={product.name} />
            <p></p>
            <ShowInstanceComponent label={"External Reference GUID"} instanceProp={null} productProp={product?.papi_guid} />
            <p></p>
            <label className="form-label lighter-gray">External ID</label>
            <p className="mb-3">
              {product?.instance?.external_id || NOVALUE}
            </p>
            <div className="row">
              <div className="col-6">
                <ShowInstanceComponent label={"EAN"} instanceProp={product?.instance?.ean} productProp={product.ean} />
              </div>
              <div className="col-6">
                <ShowInstanceComponent label={"UPC"} instanceProp={product?.instance?.upc} productProp={product.upc} />
              </div>
            </div>
              <div className="my-3">
                <ShowInstanceComponent label={"Region"} instanceProp={product?.instance?.region} productProp={product?.region} />
              </div>
          </div>
        </div>
        <div className="card my-3">
          <div className="card-body pb-0">
            <ShowInstanceComponent label={"Supplier"} instanceProp={product?.instance?.supplier} productProp={product?.supplier?.name} />
          </div>
          <div className="card-body pb-0">
            <ShowInstanceComponent label={"Brand Family"} instanceProp={product?.instance?.brand_family} productProp={product?.brand_family?.name} />
          </div>
          <div className="card-body">
            <ShowInstanceComponent label={"Brand"} instanceProp={product?.instance?.brand} productProp={product?.brand?.name} />
          </div>
        </div>
        <div className="card my-3">
          <div className="card-body pb-0">
            <ShowInstanceComponent label={"Classification"} instanceProp={product?.instance?.classification} productProp={product?.classification?.name} />
          </div>
          <div className="card-body">
            <ShowInstanceComponent label={"Subclassification"} instanceProp={product?.instance?.subclassification} productProp={product?.subclassification?.name} />
          </div>
        </div>
        <div className="card my-3">
          <div className="card-body">
            <div className="row">
              <div className="col-4">
                <ShowInstanceComponent label={"Pack Size"} instanceProp={product?.instance?.pack_size} productProp={product?.pack_size} />
              </div>
              <div className="col-4">
                <ShowInstanceComponent label={"Pack Type"} instanceProp={product?.instance?.pack_type} productProp={product?.pack_type} />
              </div>
              <div className="col-4">
                <ShowInstanceComponent label={"Size"} instanceProp={product?.instance?.size} productProp={product?.size} />
              </div>
            </div>
          </div>
        </div>
        <label className="form-label">Custom Attributes</label>
        <div className="card my-3">
          <div className="card-body">
            {product.attributes && (
              <div className="attributes-data">
                {product?.attributes?.map((attribute: ProductAttribute, index: number) => (
                  <div key={index} className={`attribute ${index !== product.attributes.length - 1 ? 'mb-4' : ''}`}>
                    <p className="w-75">
                      <span className="form-label lighter-gray">{attribute.name}</span>
                      <span className="d-block">{displayAttributeValue(attribute.value)}</span>
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="bottom-bar">
          <NavLink to={`/customer-product-lists/products/${previewId}/edit/${product?.id}/${viewProductInstanceId}`} replace={true}>
            <button type="button" className="save-ui btn btn-primary btn-block fw-bolder float-end"><EditIcon fontSize="small" />Edit</button>
          </NavLink>
        </div>
      </div>
      <div className="product-photos col-6">
        {product?.thumbnail_url ? <>
          <div className="row">
            <div className="col-6 relative">
              <span className="image-container">
                <img className="w-100-fit pr-5" src={product?.thumbnail_url} />
              </span>
            </div>
          </div></> : <>
          <div className="d-flex justify-content-center">
            <span className="color-grey grey-box">
              <PermMediaOutlinedIcon sx={{ fontSize: 120 }} />
            </span>
          </div></>}
      </div>
    </div >
  );
}

export default ViewProductInstance;
