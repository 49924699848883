import { NOVALUE } from "shared/constants/constants";

export const ShowInstanceComponent = (props: any) => {
  const { instanceProp, productProp, label } = props;
  return (
    <>
      {label && <label className="form-label lighter-gray">{label}</label>}
      {instanceProp ? <>
        <p className="mb-0">{instanceProp || NOVALUE} <br />
          <span className="lighter-gray extra-small">{productProp}</span>
        </p>
      </> :
        <>
          <p className="mb-0">{productProp || NOVALUE}</p>
        </>
      }
    </>
  )
}
