import axios from "axios";
import { Product, ProductAttribute } from "models/product";
import { createContext, useReducer, useContext, ReactNode } from "react";
import { productReducer, initialState } from "reducers/productsReducer";
import { clearActionFailure, clearActionSuccess, convertFiltersToQueryString, generateSortQueryParam, GetEntitiesProps, Meta, setFailure, setLoading } from "models/util";
import { processDateObjects, prodcessDateObject } from "shared/functions/processDateObjects";
import { CUSTOM_ATTR_DEFAULT_ERROR } from "shared/constants/constants";

const ProductContext = createContext(initialState);

interface Props {
  children?: ReactNode
}

const processProduct = (p: Product) => {
  p.subclassification = p.subclassification || null;
  p.classification = p.classification || null;
  p.supplier = p.supplier || null;
  p.brand_family = p.brand_family || null;
  return p;
}

export const ProductProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(productReducer, initialState);
  const formatCustomAttrError = (error: string, name: string) => {
    const msg = `Failed to update ${name}, error message: ${error}; `
    return [msg]
  }

  const getProducts = ({ page = 1, take = 20, filters = [], sort = [] }: GetEntitiesProps) => {
    const f = convertFiltersToQueryString(filters);
    setLoading(dispatch);
    const url = `${process.env.REACT_APP_API_URL}/products?page=${page}&items_per_page=${take}${f}${generateSortQueryParam(sort)}`;
    axios.get(url)
      .then(res => {
        if (res?.data?.data) {
          setProducts(processDateObjects(res?.data?.data), res?.data?.meta);
        }
      },
        () => setFailure(dispatch, "Error getting products"))
  }

  const setProducts = (products: Product[], meta?: Meta) => {
    dispatch({
      type: "success",
      results: {
        products,
        meta,
      },
    });
  };

  const updateProductInExistingCollection = (updatedProduct: Product) => {
    const updatedProducts = state.products.map((product) => {
      if (product.id === updatedProduct.id) {
        return prodcessDateObject(updatedProduct);
      }
      return product;
    });
    setProducts(updatedProducts, state.meta);
  };

  const createProduct = (p: Product) => {
    p = processProduct(p);
    return axios.post(`${process.env.REACT_APP_API_URL}/products`, p)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Product has been created!" })
        setTimeout(() => {
          getProducts({ page: 1, take: 20 });
        }, 1000);
        setTimeout(() => clearActionSuccess(dispatch), 5000)
      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(dispatch), 5000)
          return error;
        })
  }

  const editProduct = (p: Product) => {
    p = processProduct(p);
    return axios.put(`${process.env.REACT_APP_API_URL}/products/${p.id}`, p)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Product has been updated!" })
        setTimeout(() => getProducts({ page: 1, take: 20 }), 2000);
        setTimeout(() => clearActionSuccess(dispatch), 5000)
      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(dispatch), 5000)
          return error;
        })
  }

  const createAttributeValue = (productId: string, productAttribute: ProductAttribute) => {
    const params = { value: productAttribute.value, attribute_definition_id: productAttribute.attribute_definition_id }

    return axios.post(`${process.env.REACT_APP_API_URL}/products/${productId}/attribute_values`, params)
      .then(res => {
        if (!state.attrErrors || state.attrErrors.length === 0) {
          dispatch({ type: "actionSuccess", message: "Product has been updated!" })
        }
      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "attributeErrors", attrErrors: formatCustomAttrError(error.errors[0].message, productAttribute.name) })
          } else {

            dispatch({ type: 'attributeErrors', attrErrors: formatCustomAttrError(`${CUSTOM_ATTR_DEFAULT_ERROR}`, productAttribute.name) })
          }
          return error;
        }
      )
  }

  const editAttributeValue = (productId: string, productAttribute: ProductAttribute) => {
    const formatEmptyStringtoNull = (value: string | string[]) => {
      if (value.length !== 0) {
        return value
      } else {
        return null
      }
    }
    const params = { value: formatEmptyStringtoNull(productAttribute.value) }

    return axios.put(`${process.env.REACT_APP_API_URL}/products/${productId}/attribute_values/${productAttribute.id}`, params)
      .then(res => {
        if (!state.attrErrors || state.attrErrors.length === 0) {
          dispatch({ type: "actionSuccess", message: "Product has been updated!" })
        }
      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "attributeErrors", attrErrors: formatCustomAttrError(error.errors[0].message, productAttribute.name) })
          } else {
            dispatch({ type: 'attributeErrors', attrErrors: formatCustomAttrError(`${CUSTOM_ATTR_DEFAULT_ERROR}`, productAttribute.name) })
          }
          return error;
        }
      )
  }

  const addPhotoToProduct = (id: string, photo_id: string) => {
    let p = {
      photo_ids: [photo_id]
    }
    return axios.post(`${process.env.REACT_APP_API_URL}/products/${id}/photos`, p)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Photo uploaded!" })
        updateProductInExistingCollection(res?.data?.data);
        setTimeout(() => clearActionSuccess(dispatch), 2000)
      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(dispatch), 5000)
          return error;
        })
  }

  const addPhotosToProduct = (id: string, photo_ids: string[]) => {
    let p = {
      photo_ids: photo_ids
    }
    return axios.post(`${process.env.REACT_APP_API_URL}/products/${id}/photos`, p)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Photo uploaded!" })
        updateProductInExistingCollection(res?.data?.data);
        setTimeout(() => clearActionSuccess(dispatch), 2000)
      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(dispatch), 5000)
          return error;
        })
  }

  const makePhotoPrimary = (id: string, photo_id: string) => {
    let p = {
      photo_id: photo_id,
      main: true
    }
    return axios.put(`${process.env.REACT_APP_API_URL}/products/${id}/photos`, p)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Photo updated!" })
        updateProductInExistingCollection(res?.data?.data);
        setTimeout(() => clearActionSuccess(dispatch), 2000)
      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(dispatch), 5000)
          return error;
        })
  }

  const updatePhoto = async (productId: string, photoId: string, formData: FormData) => {
    try {
      console.log("Updating photo...");
      await axios.delete(`${process.env.REACT_APP_API_URL}/photos/${photoId}`);

      axios.post(`${process.env.REACT_APP_API_URL}/photos`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        photoId = res.data.data.id;
        addPhotosToProduct(productId, [photoId])
      });
    } catch (error) {
      console.error("Error updating photo:", error);
      throw new Error("Failed to update photo.");
    }
  };

  const deleteAllPhotos = (productId: string) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/products/${productId}/photos`)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Photos have been deleted!" });
        setTimeout(() => getProducts({ page: 1, take: 20 }), 2000);
        setTimeout(() => clearActionSuccess(dispatch), 5000);
      },
        (error) => {
          const err = error.response?.data;
          if (err && err.errors && err.errors[0]?.message) {
            dispatch({ type: "failure", error: err.errors[0].message });
          } else {
            dispatch({ type: "failure" });
          }
          setTimeout(() => clearActionFailure(dispatch), 5000);
          return error;
        });
  }

  const deletePhoto = (photoId: string) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/photos/${photoId}`)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Photo has been deleted!" });
        setTimeout(() => clearActionSuccess(dispatch), 5000);
      },
        (error) => {
          const err = error.response?.data;
          if (err && err.errors && err.errors[0]?.message) {
            dispatch({ type: "failure", error: err.errors[0].message });
          } else {
            dispatch({ type: "failure" });
          }
          setTimeout(() => clearActionFailure(dispatch), 5000);
          return error;
        });
  };

  const removeProductbyId = (id: string | undefined) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/products/${id}`).then(res => {
      dispatch({ type: "actionSuccess", message: "Product has been deleted!" })
      setTimeout(() => getProducts({ page: 1, take: 20 }), 2000);
      setTimeout(() => clearActionSuccess(dispatch), 5000)
    });
  }

  const value = {
    isLoading: state.isLoading,
    error: state.error,
    attrErrors: state.attrErrors,
    products: state.products,
    meta: state.meta,
    isSuccess: state.isSuccess,
    message: state.message,
    createProduct,
    removeProductbyId,
    editProduct,
    setProducts,
    getProducts,
    addPhotoToProduct,
    addPhotosToProduct,
    makePhotoPrimary,
    updatePhoto,
    deleteAllPhotos,
    deletePhoto,
    createAttributeValue,
    editAttributeValue,
    setLoading,
    setFailure
  };
  return <ProductContext.Provider value={value}>{children}</ProductContext.Provider>;
};

const useProducts = () => {
  const context = useContext(ProductContext);

  if (context === undefined) {
    throw new Error("useProducts must be used within ProductsContext");
  }

  return context;
};

export default useProducts;
