import { Drawer } from "@mui/material";
import axios from "axios";
import CommonLayout from "components/layouts/common-layout";
import { OtherObjectsTable } from "components/otherObjects/otherObjectsTable";
import { OtherObjectsProvider } from "context/otherObjectsContext";
import { FormMode } from "models/util";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditOtherObject from "./editOtherObject";
import AddIcon from '@mui/icons-material/Add';
import { LabelsProvider } from "context/labelsContext";
import ViewOtherObject from "./viewOtherObject";
import CreateOtherObject from "./createOtherObject";

interface OtherObjectProps {
  mode: FormMode
}

const OtherObjectsPage = (props: OtherObjectProps) => {
  const [drawerTitle, setDrawerTitle] = useState<string>("Other Object")
  let { editId, previewId } = useParams();
  const [otherObject, setOtherObject] = useState<any>({});
  const [mode, setMode] = useState<FormMode>(props.mode)
  const navigate = useNavigate();

  useEffect(() => {
    if (editId) {
      setOtherObject(null);
      setMode(FormMode.Edit);
      getOtherObjectById(editId).then((res: any) => {
        setOtherObject(res.data.data)
      })
    }
  }, [editId]);

  useEffect(() => {
    if (previewId) {
      setMode(FormMode.View);
      getOtherObjectById(previewId).then((res: any) => {
        setOtherObject(res.data.data)
      })
    }
  }, [previewId]);

  useEffect(() => {
    if (mode === FormMode.Create) {
      setDrawerTitle("New Other Object")
    }
    else if (mode === FormMode.Edit) {
      setDrawerTitle("Edit Other Object")
    }
    else {
      setDrawerTitle("Other Object")
    }
  }, [mode]);

  const getOtherObjectById = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/other_objects/${id}`)
  }

  const onExit = () => {
    setMode(FormMode.None);
    setOtherObject(undefined);
    navigate("/other-objects", { replace: true })
  }
  return (
    <CommonLayout>
      <h2 className="float-start">Other Object</h2>
      <button onClick={() => {
        setMode(FormMode.Create)
        navigate("/other-objects/new", { replace: true })
      }} className="btn btn-primary btn-block fw-bolder float-end">
        <AddIcon />New Other Object
      </button>
      <OtherObjectsProvider>
        <LabelsProvider>

          <div className="clearfix"></div>
          <OtherObjectsTable />
          <Drawer
            PaperProps={{ style: { width: '600px' } }}
            anchor={'right'}
            open={mode !== FormMode.None}
            onClose={onExit}
          >
            <section className="sidebar-entity p-4">
              <h2 className="float-start">
                <ArrowBackIcon onClick={onExit} className="primary-col pointer" /><span className="px-2">{drawerTitle}</span>
              </h2>
              <div className="clearfix" />
              {mode === FormMode.Edit && otherObject && <EditOtherObject onExit={onExit} otherObject={otherObject} />}
              {mode === FormMode.Create && <CreateOtherObject onExit={onExit} />}
              {mode === FormMode.View && <ViewOtherObject otherObject={otherObject} />}
            </section>
          </Drawer>
        </LabelsProvider>
      </OtherObjectsProvider>
    </CommonLayout>

  )
}


export default OtherObjectsPage;
