import { Import } from "models/import";
import { DEFAULT_ERROR, Meta } from "models/util";

export interface SuplierState {
  isLoading: boolean;
  imports: Import[];
  meta?: Meta,
  error?: string;
  getImports?: any;
  isSuccess?: boolean,
  message?: string,
  clearActionSuccess?: any;
}

export const initialState: SuplierState = {
  isLoading: true,
  imports: [],
  meta: { current_page: 1, page_size: 20, total_count: 20 },
};

type Action =
  | { type: 'request' }
  | { type: 'success', results: { imports: Import[], meta: Meta } }
  | { type: 'failure', error?: string }
  | { type: 'actionSuccess', message: string }
  | { type: 'clearActionSuccess' }
  | { type: 'clearActionFailure' }

export function importReducer(state: SuplierState, action: Action): SuplierState {
  switch (action.type) {
    case 'request':
      return { ...state, isLoading: true, error: undefined };
    case 'success':
      return { ...state, isLoading: false, imports: action.results.imports, meta: action.results.meta };
    case 'failure':
      return { ...state, isLoading: false, error: action.error || DEFAULT_ERROR };
    case 'actionSuccess':
      return { ...state, isSuccess: true, message: action.message, error: undefined }
    case 'clearActionSuccess':
      return { ...state, isSuccess: false, message: undefined }
    case 'clearActionFailure':
      return { ...state, error: undefined }
  }
}
