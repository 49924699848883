import axios from 'axios';
import { createContext, useReducer, useContext, ReactNode } from 'react';
import { predefinedClassLabelsReducer, initialState } from 'reducers/predefinedClassLablesReducer';
import { processDateObjects } from "shared/functions/processDateObjects";
import { generateSortQueryParam ,convertFiltersToQueryString, GetEntitiesProps } from "models/util";

const PredefinedClassLabelsContext = createContext(initialState);

interface Props {
  children?: ReactNode
}

interface PredefinedClassLabelsIndexProps extends GetEntitiesProps {
  predefinedClassId: string
}

export const PredefinedClassLabelsProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(predefinedClassLabelsReducer, initialState);

  const getPredefinedClassLabels = ({ page = 1, take = 20, filters = [], sort = [], predefinedClassId }: PredefinedClassLabelsIndexProps) => {
    const f = convertFiltersToQueryString(filters);
    axios.get(`${process.env.REACT_APP_API_URL}/predefined_classes/${predefinedClassId}/labels?page=${page}&items_per_page=${take}${f}${generateSortQueryParam(sort)}`)
      .then(res => {
        dispatch({ type: 'success', results: { labels: processDateObjects(res.data.data), meta: res.data.meta } })
      },
        () => dispatch({ type: 'failure', error: "Error getting Predefined Class Labels" }));
  }

  const value = {
    isLoading: state.isLoading,
    error: state.error,
    labels: state.labels,
    meta: state.meta,
    getPredefinedClassLabels: getPredefinedClassLabels
  }
  return <PredefinedClassLabelsContext.Provider value={value}>{children}</PredefinedClassLabelsContext.Provider>;
};

const usePredefinedClassLabels = () => {
  const context = useContext(PredefinedClassLabelsContext);

  if (context === undefined) {
    throw new Error('usePredefinedClassLabels must be used within a PredefinedClassLabelsProvider');
  }

  return context;
};


export default usePredefinedClassLabels;
