import CommonLayout from "components/layouts/common-layout"
import { useParams, NavLink } from 'react-router-dom'
import useModelLists from 'context/modelListsContext';
import Toast from 'components/toaster/toastr';
import ErrorToast from 'components/toaster/errorToastr';
import { Drawer } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ModelList } from "models/model_list";
import useModelListProducts from "context/modelListProductsContext";
import { Grid, GridColumn, GridDataStateChangeEvent, GridFilterChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { gridSettings, initialSort } from "configs/gridSettings";
import { RangeFilterCell } from 'components/grid/DateRangeFilterCell';
import { useSearchPagination } from 'components/hooks/useSearchPagination';
import { FullPageLoader } from 'components/shared/fullPageLoader';
import { initialDataState } from 'configs/initialDataState';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import { ModelListProductsTable } from "components/modelLists/modelListProductsTable";
import { ModelListProductsProvider } from "context/modelListProductsContext";
import { BrandCategoriesProvider } from "context/brandCategoriesContext";
import { CategoriesProvider } from "context/categoriesContext";
import { BrandsProvider } from "context/brandsContext";
import { SuppliersProvider } from "context/suppliersContext";
import { ClassificationsProvider } from "context/classificationsContext";
import { SubClassificationsProvider } from "context/subClassificationContext";
import { BrandFamiliesProvider } from "context/brandsFamiliesContext";
import { featureFlags } from 'configs/featureFlags';
import { useExportRequests } from 'hooks/useExportRequests';

const ModelListProductsPage = (props: any) => {
  const { previewId } = useParams();
  const { isSuccess, error, message, modelLists, getModelList } = useModelLists();
  const { error: productsError, meta, getModelListProducts, products } = useModelListProducts();
  const navigate = useNavigate();
  const [modelList, setModelList] = useState({} as ModelList);
  const [product, setProduct] = useState<any>({});
  const [filter, setFilter] = useState<CompositeFilterDescriptor | any>(undefined);
  const [sort, setSort] = useState(initialSort);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchPageNumber, searchPageSize] = useSearchPagination();
  const [productsDataState, setproductsDataState] = useState(initialDataState);
  const [requestExport] = useExportRequests();

  useEffect(() => {
    getModelListProducts({ modelListId: previewId, sort: sort });
      const fetchData = async () => {
      await getModelList(previewId);
      const response = await getModelListProducts({ modelListId: previewId, sort: sort });
      
      if (!response || response.length === 0) {
        console.log("Products data is empty");
        return;
      }
      
      const productsData = { ...productsDataState }
      productsData.take = searchPageSize;
      productsData.skip = (searchPageNumber - 1) * searchPageSize;
      productsData.isInitial = false;
      setproductsDataState(productsData);
    };
  }, [previewId]);

  useEffect(() => {
    if (modelLists && modelLists.length > 0) {
      setModelList(modelLists[0]);
    }
  }, [modelLists])

  useEffect(() => {
    if (productsDataState.isInitial) return;
    const page = productsDataState.skip / productsDataState.take + 1;
    const take = String(productsDataState.take);
    setSearchParams(`?${new URLSearchParams({ pageNumber: String(page), pageSize: take })}`);
    getModelListProducts({ page: page, take: productsDataState.take, sort: sort, filters: (filter?.filters || []), modelListId: previewId })
  }, [productsDataState])

  useEffect(() => {
    if (filter === undefined) return;
    const getData = setTimeout(() => {
      getModelListProducts({ filters: filter?.filters, modelListId: previewId })
    }, 600);

    return () => clearTimeout(getData);
  }, [filter]);

  const filterChange = (event: GridFilterChangeEvent) => {
    setFilter(event.filter);
  }

  const sortChanged = (sort: SortDescriptor[]) => {
    const page = productsDataState.skip / productsDataState.take + 1;
    getModelListProducts({ page: page, take: productsDataState.take, filters: filter?.filters, sort: sort, modelListId: previewId })
  }

  const fetchPhotoUrl = (dataItem: any) => {
    return dataItem.photos[0].url;
  }

  const handleExport = () => {
    requestExport('model_list', ['guid', 'name', 'full_name', 'labels', 'ean', 'upc', 'category', 'supplier', 'brand_family', 'brand', 'classification', 'subclassification', 'pack_size', 'pack_type', 'size', 'region', 'created_at', 'updated_at'], filter?.filters, { model_list_id: previewId })
  }

  return (
    <CommonLayout>
      <h2 className="float-start">
        <ArrowBackIcon onClick={() => { navigate("/model-lists", { replace: true }) }} className="primary-col pointer" />
        <span className="px-2">{modelList?.name}</span>
      </h2>
      {featureFlags.exports && (
        <button onClick={() => {
          handleExport();
        }} className="btn btn-primary btn-block fw-bolder float-end floating-button-right">
          Export CSV
        </button>
      )}
      <ModelListProductsProvider>
        <div className="clearfix"></div>
        <ModelListProductsTable />
      </ModelListProductsProvider>
    </CommonLayout>
  )
}

export default ModelListProductsPage;

