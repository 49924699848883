import axios from "axios";
import { Category } from "models/category";
import { createContext, useReducer, useContext, ReactNode } from "react";
import { categoriesReducer, initialState } from "reducers/categoriesReducer";
import { clearActionFailure, clearActionSuccess, convertFiltersToQueryString, Meta, setFailure, setLoading, generateSortQueryParam, GetEntitiesProps } from "models/util";
import { processDateObjects } from "shared/functions/processDateObjects";

const CategoriesContext = createContext(initialState);

interface Props {
  children?: ReactNode
}

export const CategoriesProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(categoriesReducer, initialState);

  const removeCategoryById = (id: string | undefined) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/categories/${id}`).then(res => {
      dispatch({ type: "actionSuccess", message: "Category has been deleted!" })
      setTimeout(() => getCategories({ page: 1, take: 20 }), 2000);
      setTimeout(() => clearActionSuccess(dispatch), 5000)
    });
  }

  const getCategories = ({ page = 1, take = 20, filters = [], sort = [] }: GetEntitiesProps) => {
    const f = convertFiltersToQueryString(filters);
    setLoading(dispatch);
    axios.get(`${process.env.REACT_APP_API_URL}/categories/?page=${page}&items_per_page=${take}${f}${generateSortQueryParam(sort)}`)
      .then(res => {
        setCategories(processDateObjects(res.data.data), res.data.meta)
      },
        () => setFailure(dispatch, "Error getting Categories"))
  }

  const setCategories = (categories: Category[], meta: Meta) => {
    dispatch({
      type: "success",
      results: { categories: categories, meta },
    });
  };

  const createCategory = (c: Category) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/categories`, c)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Category has been created!" })
        setTimeout(() => {
          getCategories({ page: 1, take: 20 });
        }, 1000);
        setTimeout(() => clearActionSuccess(dispatch), 5000)
      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(dispatch), 5000)
          return error;
        })
  }

  const editCategory = (c: Category) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/categories/${c.id}`, c)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Category has been updated!" })
        setTimeout(() => getCategories({ page: 1, take: 20 }), 2000);
        setTimeout(() => clearActionSuccess(dispatch), 5000)
      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(dispatch), 5000)
          return error;
        })
  }

  const value = {
    isLoading: state.isLoading,
    error: state.error,
    categories: state.categories,
    meta: state.meta,
    message: state.message,
    isSuccess: state.isSuccess,
    setCategories: setCategories,
    getCategories: getCategories,
    editCategory: editCategory,
    createCategory: createCategory,
    removeCategoryById: removeCategoryById,
    setLoading,
    setFailure,
  };
  return <CategoriesContext.Provider value={value}>{children}</CategoriesContext.Provider>;
};

const useCategories = () => {
  const context = useContext(CategoriesContext);

  if (context === undefined) {
    throw new Error("useCategories must be used within CategoriesContext");
  }

  return context;
};

export default useCategories;
