import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useBrands from "context/brandsContext";
import { Checkbox, FormControlLabel } from "@mui/material";
import { SaveButton } from "components/shared/saveButton";

const AddBrand = (props: any) => {
  const { createBrand } = useBrands();
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch
  } = useForm({
    defaultValues: {
      name: "",
      create_label_automatically: true
    }
  });

  const name = watch("name")

  return (
    <form
      className={errors.name ? "was-validated" : "needs-validation"}
      onSubmit={handleSubmit((data) => {
        createBrand(data).then((error: any) => {
          if (!error) {
            props.onExit()
          }
        }
        )
      })}
      noValidate
    >
      <div className="card">
        <div className="card-body">
          <label className="form-label">Name</label>
          <input className="form-control"
            {...register("name", { required: true })}
            required
          />
          {errors.name && <div className="invalid-feedback">
            <p className="warning-icon">This field is required.</p>
          </div>}
        </div>
      </div>
      <div className="card my-3">
        <div className="card-body">
          <FormControlLabel
            control={
              <Controller
                name={"create_label_automatically"}
                control={control}
                render={({ field: props }) => (
                  <Checkbox
                    {...props}
                    checked={!!props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label={<>
              <label>Create & assign label automatically<br /><span className="small small-bottom"> {name}</span></label>
            </>}
          />
        </div>
      </div>

      <SaveButton />
    </form >
  );
}

export default AddBrand;
