export interface AuthState {
  isAuthenticated: boolean,
  authenticate?: any,
  error?: string,
  isForbidden: boolean,
}

export const initialState: AuthState = {
  isAuthenticated: !!localStorage.getItem("auth_token"),
  isForbidden: false,
};

type Action =
  | { type: 'success' }
  | { type: 'failure', error?: string }
  | { type: 'setForbiddenError', message: string }
  | { type: 'resetForbiddenError' };

export function authReducer(state: AuthState, action: Action): AuthState {
  switch (action.type) {
    case 'success':
      return { ...state, isAuthenticated: true, error: undefined, isForbidden: false };
    case 'failure':
      return { ...state, isAuthenticated: false, error: action.error, isForbidden: false };
    case 'setForbiddenError':
      console.log('Setting isForbidden to true');
      return { ...state, isAuthenticated: true, error: action.message, isForbidden: true };
    case 'resetForbiddenError':
      return { ...state, isAuthenticated: true, error: undefined, isForbidden: false };
    default:
      return state;
  }
}

