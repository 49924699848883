import useAuthentication from "context/authenticationContext";
import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

interface Props {
  children?: React.ReactNode
}

export const Protected = ({ children }: Props) => {

  const { pathname, search } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, search]);

  if (!localStorage.getItem("auth_token")) {
    return <Navigate to='/' />
  }

  return <>{children}</>
}
