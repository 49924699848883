import { BrandsTable } from "components/brands/brandsTable";
import { BrandsProvider } from "context/brandsContext";
import { Drawer } from "@mui/material";
import CommonLayout from "components/layouts/common-layout";
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import AddBrand from "./addBrand";
import EditBrand from "./editBrand";
import ViewBrand from "./viewBrand";
import { FormMode } from "models/util";

interface BrandsPage {
  mode: FormMode
}

interface BrandsProps {
  mode: FormMode
}

const BrandsPage = (props: BrandsProps) => {
  const [drawerTitle, setDrawerTitle] = useState<string>("Brand")
  let { editId, previewId } = useParams();
  const [brand, setBrand] = useState<any>({});
  const [mode, setMode] = useState<FormMode>(props.mode)
  const navigate = useNavigate();

  useEffect(() => {
    if (editId) {
      setBrand(null);
      setMode(FormMode.Edit);
      getBrandById(editId).then((res: any) => {
        setBrand(res.data.data)
      })
    }
  }, [editId])

  useEffect(() => {
    if (previewId) {
      setMode(FormMode.View);
      getBrandById(previewId).then((res: any) => {
        setBrand(res.data.data)
      })
    }
  }, [previewId])

  useEffect(() => {
    if (mode === FormMode.Create) {
      setDrawerTitle("New Brand")
    }
    else if (mode === FormMode.Edit) {
      setDrawerTitle("Edit Brand")
    }
    else {
      setDrawerTitle("Brand")
    }
  }, [mode])

  const getBrandById = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/brands/${id}`)
  }

  const onExit = () => {
    setMode(FormMode.None);
    navigate("/Brands", { replace: true })
  }

  return (
    <CommonLayout>
      <h2 className="float-start">Brands</h2>
      <button onClick={() => {
        navigate("/Brands/new", { replace: true })
        setMode(FormMode.Create)
      }} className="btn btn-primary btn-block fw-bolder float-end">
        <AddIcon />New Brand
      </button>
      <BrandsProvider>
        <div className="clearfix"></div>
        <BrandsTable />
        <Drawer
          PaperProps={{ style: { width: '600px' } }}
          anchor={'right'}
          open={mode !== FormMode.None}
          onClose={onExit}
        >
          <section className="sidebar-entity p-4">
            <h2 className="float-start">
              <ArrowBackIcon onClick={onExit} className="primary-col pointer" /><span className="px-2">{drawerTitle}</span>
            </h2>
            <div className="clearfix" />
            {mode === FormMode.View && <ViewBrand brand={brand} />}
            {mode === FormMode.Create && <AddBrand onExit={onExit} />}
            {mode === FormMode.Edit && brand && <EditBrand onExit={onExit} brand={brand} />}
          </section>
        </Drawer>

      </BrandsProvider>
    </CommonLayout>
  )
}

export default BrandsPage;
