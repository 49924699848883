import { GridCellProps } from "@progress/kendo-react-grid";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from "@mui/material/Tooltip";

export const CopiableCell = (props: GridCellProps) => {
  const onClick = (e: any) => {
    navigator.clipboard.writeText(props.dataItem[props?.field as string])
  }
  return (
    <td className="copyCell">
      {props.field && props.dataItem[props?.field as string]}
      <Tooltip title="Copy to clipboard">
        <ContentCopyIcon onClick={onClick} className="copyButton" sx={{ fontSize: 14 }} style={{ "cursor": "pointer" }} />
      </Tooltip>
    </td>
  );
}
