import axios from "axios";
import { createContext, useReducer, useContext, ReactNode } from "react";
import { brandCategoriesReducer, initialState } from "reducers/brandCategoriesReducer";
import { convertFiltersToQueryString, Meta, generateSortQueryParam, GetEntitiesProps } from "models/util";
import { BrandCategory } from "models/brand_category";
import { processDateObjects } from "shared/functions/processDateObjects";
import { useNavigate } from "react-router-dom";

const BrandCategoriesContext = createContext(initialState);

interface Props {
  children?: ReactNode
}

export const BrandCategoriesProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(brandCategoriesReducer, initialState);
  const navigate = useNavigate();

  const removeBrandCategoryById = (id: string | undefined) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/brand_categories/${id}`).then(res => {
      dispatch({ type: "actionSuccess", message: "BrandCategory has been deleted!" })
      setTimeout(() => getBrandCategories({ page: 1, take: 20 }), 2000);
      setTimeout(() => clearActionSuccess(), 5000)
    });
  }

  const getBrandCategories = ({ page = 1, take = 20, filters = [], sort = [] }: GetEntitiesProps) => {

    const f = convertFiltersToQueryString(filters);
    setLoading();
    axios.get(`${process.env.REACT_APP_API_URL}/brand_categories?page=${page}&items_per_page=${take}${f}${generateSortQueryParam(sort)}`)
      .then(res => {
        setBrandCategories(processDateObjects(res.data.data), res.data.meta)
      },
        () => setFailure("Error getting brand categories"))
  }

  const setBrandCategories = (brandCategories: BrandCategory[], meta: Meta) => {
    dispatch({
      type: "success",
      results: { brandCategories: brandCategories, meta },
    });
  };

  const createBrandCategory = (brandCategory: BrandCategory) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/brand_categories`, brandCategory)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Brand Category has been created!" })
        setTimeout(() => { getBrandCategories({ page: 1, take: 20 }) }, 500)
        navigate("/brand-categories", { replace: true })
        setTimeout(() => clearActionSuccess(), 5000)

      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(), 5000)
          return error;
        })
  }

  const editBrandCategory = (brandCategory: BrandCategory) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/brand_categories/${brandCategory.id}`, brandCategory)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Brand Category has been updated!" });
        setTimeout(() => { getBrandCategories({ page: 1, take: 20 }) }, 500);
        navigate("/suppliers", { replace: true })
        setTimeout(() => clearActionSuccess(), 5000)

      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(), 5000)
          return error;
        })
  }
  const clearActionSuccess = () => {
    dispatch({ type: "clearActionSuccess" })
  }
  const clearActionFailure = () => {
    dispatch({ type: "clearActionFailure" })
  }

  const setLoading = () => {
    dispatch({
      type: "request",
    });
  }

  const setFailure = (errorMessage: string) => {
    dispatch({
      type: "failure",
      error: errorMessage
    });
  }

  const value = {
    isLoading: state.isLoading,
    error: state.error,
    brandCategories: state.brandCategories,
    meta: state.meta,
    message: state.message,
    setBrandCategories: setBrandCategories,
    getBrandCategories: getBrandCategories,
    createBrandCategory: createBrandCategory,
    editBrandCategory: editBrandCategory,
    removeBrandCategoryById: removeBrandCategoryById,
    isSuccess: state.isSuccess,
    setLoading,
    setFailure
  };
  return <BrandCategoriesContext.Provider value={value}>{children}</BrandCategoriesContext.Provider>;
};

const useBrandCategories = () => {
  const context = useContext(BrandCategoriesContext);

  if (context === undefined) {
    throw new Error("useBrandCategories must be used within BrandCategoriesContext");
  }

  return context;
};

export default useBrandCategories;
