import CommonLayout from "components/layouts/common-layout";
import { CategoriesTable } from "components/categories/categoriesTable";
import { CategoriesProvider } from "context/categoriesContext";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Drawer } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ViewCategory from "./viewCategory";
import AddCategory from "./addCategory";
import EditCategory from "./editCategory";
import { FormMode } from "models/util";

interface CategoriesProps {
  mode: FormMode
}

const CategoriesPage = (props: CategoriesProps) => {

  const [drawerTitle, setDrawerTitle] = useState<string>("Categories")
  let { editId, previewId } = useParams();
  const [category, setCategory] = useState<any>({});
  const [mode, setMode] = useState<FormMode>(props.mode)
  const navigate = useNavigate();

  useEffect(() => {
    if (editId) {
      setCategory(null);
      setMode(FormMode.Edit);
      getCategoryById(editId).then((res: any) => {
        setCategory(res.data.data)
      })
    }
  }, [editId])

  useEffect(() => {
    if (previewId) {
      setMode(FormMode.View);
      getCategoryById(previewId).then((res: any) => {
        setCategory(res.data.data)
      })
    }
  }, [previewId])

  useEffect(() => {
    if (mode === FormMode.Create) {

      setDrawerTitle("New Category")
    }
    else if (mode === FormMode.Edit) {
      setDrawerTitle("Edit Category")
    }
    else {
      setDrawerTitle("Category")
    }
  }, [mode])

  const getCategoryById = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/categories/${id}`)
  }

  const onExit = () => {
    setMode(FormMode.None);
    setCategory(null);
    navigate("/categories", { replace: true })
  }

  return (
    <CommonLayout>
      <h2 className="float-start">Categories</h2>
      <button onClick={() => {
        navigate("/categories/new", { replace: true })
        setMode(FormMode.Create)
      }} className="btn btn-primary btn-block fw-bolder float-end">
        <AddIcon />New Category
      </button>
      <CategoriesProvider>
        <div className="clearfix"></div>
        <CategoriesTable />
        <Drawer
          PaperProps={{ style: { width: '600px' } }}
          anchor={'right'}
          open={mode !== FormMode.None}
          onClose={onExit}
        >
          <section className="sidebar-entity p-4">
            <h2 className="float-start">
              <ArrowBackIcon onClick={onExit} className="primary-col pointer" /><span className="px-2">{drawerTitle}</span>
            </h2>
            <div className="clearfix" />
            {mode === FormMode.View && <ViewCategory category={category} />}
            {mode === FormMode.Create && <AddCategory onExit={onExit} />}
            {mode === FormMode.Edit && category && <EditCategory onExit={onExit} category={category} />}
          </section>
        </Drawer>

      </CategoriesProvider>
    </CommonLayout>
  )
}

export default CategoriesPage;
