import axios from "axios";
import { Brand } from "models/brand";
import { createContext, useReducer, useContext, ReactNode } from "react";
import { brandReducer, initialState } from "reducers/brandsReducer";
import { convertFiltersToQueryString, Meta, generateSortQueryParam, GetEntitiesProps } from "models/util";
import { processDateObjects } from "shared/functions/processDateObjects";
import { useNavigate } from "react-router-dom";

const BrandsContext = createContext(initialState);

interface Props {
  children?: ReactNode
}

export const BrandsProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(brandReducer, initialState);
  const navigate = useNavigate();

  const removeBrandById = (id: string | undefined) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/brands/${id}`).then(res => {
      dispatch({ type: "actionSuccess", message: "Brand has been deleted!" })
      setTimeout(() => getBrands({ page: 1, take: 20 }), 2000);
      setTimeout(() => clearActionSuccess(), 5000)
    });
  }

  const getBrands = ({ page = 1, take = 20, filters = [], sort = [] }: GetEntitiesProps) => {
    const f = convertFiltersToQueryString(filters);
    setLoading();
    axios.get(`${process.env.REACT_APP_API_URL}/brands/?page=${page}&items_per_page=${take}${f}${generateSortQueryParam(sort)}`)
      .then(res => {
        setBrands(processDateObjects(res.data.data), res.data.meta)
      },
        () => setFailure("Error getting brands"))
  }
  const createBrand = (brand: Brand) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/brands`, brand)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Brand has been created!" })
        getBrands({ page: 1, take: 20 });
        navigate("/suppliers", { replace: true })
        setTimeout(() => clearActionSuccess(), 5000)

      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(), 5000)
          return error;
        })
  }

  const editBrand = (brand: Brand) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/brands/${brand.id}`, brand)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Brand has been updated!" })
        getBrands({ page: 1, take: 20 });
        navigate("/suppliers", { replace: true })
        setTimeout(() => clearActionSuccess(), 5000)

      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(), 5000)
          return error;
        })
  }

  const clearActionSuccess = () => {
    dispatch({ type: "clearActionSuccess" })
  }
  const clearActionFailure = () => {
    dispatch({ type: "clearActionFailure" })
  }
  const setBrands = (brands: Brand[], meta: Meta) => {
    dispatch({
      type: "success",
      results: { brands: brands, meta },
    });
  };

  const setLoading = () => {
    dispatch({
      type: "request",
    });
  }

  const setFailure = (errorMessage: string) => {
    dispatch({
      type: "failure",
      error: errorMessage
    });
  }

  const value = {
    isLoading: state.isLoading,
    error: state.error,
    brands: state.brands,
    meta: state.meta,
    setBrands: setBrands,
    getBrands: getBrands,
    editBrand: editBrand,
    removeBrandById: removeBrandById,
    createBrand: createBrand,
    setLoading,
    setFailure,
    isSuccess: state.isSuccess,
    message: state.message
  };
  return <BrandsContext.Provider value={value}>{children}</BrandsContext.Provider>;
};

const useBrands = () => {
  const context = useContext(BrandsContext);

  if (context === undefined) {
    throw new Error("useBrands must be used within BrandsContext");
  }

  return context;
};

export default useBrands;
