import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

export function DropZone(props: any) {
  const [filename, setFilename] = React.useState<string>();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file: Blob) => {
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)
      setFilename(file.name)
      props.onFileAdded(file);
    })

  }, []);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop, accept: { "text/csv": [".csv"] }, maxFiles: 1 });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  return (
    <div className="container">
      <div {...getRootProps({ style } as any)}>
        <input {...getInputProps()} />
        {filename && <>
          <UploadFileOutlinedIcon style={{ fontSize: 80, color: "#196E8C" }} />
          <p style={{ color: "#196E8C" }}><strong>{filename}</strong></p>
        </>}
        {!filename && <>
          <UploadFileOutlinedIcon style={{ fontSize: 80 }} />
          <p><strong>Choose file to upload</strong><br /> or drag and drop it here</p>
        </>}

      </div>
    </div>
  );
}
