import CommonLayout from "components/layouts/common-layout";
import { ProductPackshotsTable } from "components/productPackshots/productPackshotsTable";
import { ProductProvider } from "context/productContext";
import { FormMode } from "models/util";

interface ProductProps {
  mode: FormMode
}

const ProductPackshotsPage = (props: ProductProps) => {
  return (
    <CommonLayout>
      <h2 className="float-start">Packshots Management</h2>
      <ProductProvider>
        <div className="clearfix"></div>
        <ProductPackshotsTable />
      </ProductProvider>
    </CommonLayout >
  )
}

export default ProductPackshotsPage;
