import { useState, useEffect } from 'react';
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent, GridFilterChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import '@progress/kendo-theme-default/dist/all.css';
import { gridSettings } from "configs/gridSettings";
import { GuidCell } from "components/grid/GuidCell";
import useProductCatalogProducts from 'context/productCatalogProductsContext';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import { ActionColumns } from 'components/grid/action-column';
import { FullPageLoader } from 'components/shared/fullPageLoader';
import { initialDataState } from 'configs/initialDataState';
import { NavLink, useParams, useSearchParams } from 'react-router-dom';
import ErrorToast from 'components/toaster/errorToastr';
import Toast from 'components/toaster/toastr';
import SWDialog from 'components/shared/dialog';
import { RangeFilterCell } from 'components/grid/DateRangeFilterCell';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { NOVALUE } from 'shared/constants/constants';
import { ShowInstanceComponent } from 'modules/productCatalogs/showInstance';
import { displayAttributeValue } from "shared/functions/displayAttributeValue";

const ProductInstanceCell = ({ hasInstance, instanceProp, productProp, url }: { hasInstance: boolean, instanceProp: string | null, productProp: string, url?: string }): JSX.Element => {
  if (!hasInstance) {

    return url ?
      <>
        <NavLink to={url} replace={true}>
          {productProp === "" ? NOVALUE : productProp}< br />
        </NavLink>
      </> :
      <>
        <span>{productProp || NOVALUE}</span>
      </>
  }

  if (!!instanceProp || typeof instanceProp === "string") {
    return url ?
      <>
        <NavLink to={url} replace={true}>
          {instanceProp === "" ? NOVALUE : instanceProp}< br />
        </NavLink>
        <span className="lighter-gray">{productProp || NOVALUE}</span>
      </> :
      <>
        <span>{instanceProp === "" ? NOVALUE : instanceProp}</span>< br />
        <span className="lighter-gray">{productProp || NOVALUE}</span>
      </>
  } else {
    return url ?
      <>
        <NavLink to={url} replace={true}>
          {productProp === "" ? NOVALUE : productProp}< br />
        </NavLink>
      </> :
      <>
        <span>{productProp || NOVALUE}</span>
      </>
  }
}

export const ProductCatalogViewTable = () => {
  const [dataState, setDataState] = useState(initialDataState);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [deleteProductId, setDeleteProductId] = useState<string>("");
  const [deleteInstanceId, setDeleteInstanceId] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams()
  const { isSuccess, message, error, attrErrors, metaProductCatalogProducts, getProductCatalogProducts, removeProductCatalogProductById, productCatalogProducts } = useProductCatalogProducts();
  let { previewId } = useParams();
  const [filter, setFilter] = useState<CompositeFilterDescriptor>();
  const [sort, setSort] = useState([] as Array<SortDescriptor>);

  useEffect(() => {
    const pageNumber = searchParams.get('pageNumber') || "1";
    const pageSize = searchParams.get('pageSize') || "20";
    const _pageNumber = parseInt(pageNumber, 10);
    const _pageSize = parseInt(pageSize, 10);
    dataState.take = _pageSize;
    dataState.skip = (_pageNumber - 1) * _pageSize;
    dataState.isInitial = true;
    setDataState(dataState);
    getProductCatalogProducts({ page: _pageNumber, take: dataState.take, catalogId: previewId })
  }, []);

  useEffect(() => {
    if (dataState.isInitial) return;
    const page = dataState.skip / dataState.take + 1;
    const take = String(dataState.take);
    setSearchParams(`?${new URLSearchParams({ pageNumber: String(page), pageSize: take })}`)
    getProductCatalogProducts({ page: page, take: dataState.take, catalogId: previewId, filters: filter?.filters, sort: sort })
  }, [dataState])

  useEffect(() => {
    if (filter === undefined) return;
    const getData = setTimeout(() => {
      getProductCatalogProducts({ catalogId: previewId, filters: filter?.filters })
    }, 500);

    return () => clearTimeout(getData);
  }, [filter]);

  const removeProductCatalogProduct = () => {
    setIsDialogOpen(false);
    if (deleteInstanceId) {
      removeProductCatalogProductById(previewId, deleteProductId, deleteInstanceId);
    } else {
      removeProductCatalogProductById(previewId, deleteProductId);
    }

  }
  const filterChange = (event: GridFilterChangeEvent) => {
    setFilter(event.filter);
  }

  const sortChanged = (sort: SortDescriptor[]) => {
    const page = dataState.skip / dataState.take + 1;
    getProductCatalogProducts({ page: page, take: dataState.take, catalogId: previewId, filters: filter?.filters, sort: sort })
  }

  return (<div>
    <>
      <SWDialog
        open={isDialogOpen}
        confirmText="Delete"
        text="Are you sure that you want to delete this Product?"
        onConfirm={() => removeProductCatalogProduct()}
        onClose={() => setIsDialogOpen(false)}
      />
      {isSuccess && <Toast message={message} />}
      {error && <ErrorToast message={error} />}
      {attrErrors && <ErrorToast message={attrErrors} />}
      {productCatalogProducts && metaProductCatalogProducts ? <Grid
        filter={filter}
        onFilterChange={filterChange}
        sort={sort}
        onSortChange={(e: GridSortChangeEvent) => {
          setSort(e.sort);
          sortChanged(e.sort);
        }}
        {...gridSettings}
        style={{
          width: "1300"
        }}
        data={productCatalogProducts}
        total={metaProductCatalogProducts.total_count}
        pageSize={dataState.take}
        onDataStateChange={(e: GridDataStateChangeEvent) => {
          setDataState(e.dataState as any);
        }}
        skip={metaProductCatalogProducts.page_size * metaProductCatalogProducts.current_page - metaProductCatalogProducts.page_size}
      >
        <GridColumn field="name" title="Photo" width="90px" filterable={false} cell={o => (
          <td>
            {o.dataItem.thumbnail_url ? <img src={o.dataItem.thumbnail_url} className="grid-thumbnail" />
              :
              <div style={{ "height": "36px", "width": "36px", "float": "left" }} >
                <NoPhotographyIcon></NoPhotographyIcon>
              </div>
            }
          </td>
        )} />
        <GridColumn field="id" title="UUID" sortable={false} width="110" cell={(o: GridCellProps) => {
          return <GuidCell
            field={o.field}
            dataIndex={o.dataIndex}
            isSelected={o.isSelected}
            ariaColumnIndex={o.ariaColumnIndex}
            id={o.dataItem.id}
            dataItem={{ id: o.dataItem.id }} />

        }} />
        <GridColumn field="external_id" title="External ID" width="250" filterable={true} sortable={false} cell={(o: GridCellProps) => {
          return (<td> {o.dataItem.instance?.external_id}</td>)
        }} />
        <GridColumn field="name" title="Name" width="300px" cell={(o: GridCellProps) => (
          <td>
            <ProductInstanceCell hasInstance={!!o.dataItem.instance} instanceProp={o.dataItem.instance?.name} productProp={o.dataItem.name}
              url={o.dataItem.instance?.id ?
                "/customer-product-lists/products/" + previewId + "/view/" + o.dataItem.id + "/" + o.dataItem.instance?.id :
                "/customer-product-lists/products/" + previewId + "/view/" + o.dataItem.id
              } />
          </td>
        )} />
        <GridColumn field="ean" title="EAN" width="90px" filterable={true} cell={o => (
          <td className="instance-cell">
            <ShowInstanceComponent instanceProp={o.dataItem.instance?.ean} productProp={o.dataItem.ean} />
          </td>
        )} />
        <GridColumn field="upc" title="UPC" width="90px" filterable={true} cell={o => (
          <td className="instance-cell">
            <ShowInstanceComponent instanceProp={o.dataItem.instance?.upc} productProp={o.dataItem.upc} />
          </td>
        )} />
        <GridColumn field="supplier" width="200" title="Supplier" cell={(o: GridCellProps) => {
          return (<td>
            <ProductInstanceCell hasInstance={!!o.dataItem.instance} instanceProp={o.dataItem.instance?.supplier} productProp={o.dataItem.supplier?.name} />
          </td>)
        }} />
        <GridColumn field="brand_family" width="200" title="Brand Family" cell={(o: GridCellProps) => {
          return (<td>
            <ProductInstanceCell hasInstance={!!o.dataItem.instance} instanceProp={o.dataItem.instance?.brand_family} productProp={o.dataItem.brand_family?.name} />
          </td>)
        }} />
        <GridColumn field="brand" width="200" title="Brand" cell={(o: GridCellProps) => {
          return (<td>
            <ProductInstanceCell hasInstance={!!o.dataItem.instance} instanceProp={o.dataItem.instance?.brand} productProp={o.dataItem.brand?.name} />
          </td>)
        }} />
        <GridColumn field="classification" width="200" title="Classification" cell={(o: GridCellProps) => {
          return (<td>
            <ProductInstanceCell hasInstance={!!o.dataItem.instance} instanceProp={o.dataItem.instance?.classification} productProp={o.dataItem.classification?.name} />
          </td>)
        }} />
        <GridColumn field="subclassification" width="200" title="Subclassification" cell={(o: GridCellProps) => {
          return (<td>
            <ProductInstanceCell hasInstance={!!o.dataItem.instance} instanceProp={o.dataItem.instance?.subclassification} productProp={o.dataItem.subclassification?.name} /></td>)
        }} />
        <GridColumn field="region" title="Region" width="100" cell={(o: GridCellProps) => {
          return (<td>
            <ProductInstanceCell hasInstance={!!o.dataItem.instance} instanceProp={o.dataItem.instance?.region} productProp={o.dataItem.region} />
          </td>)
        }} />
        <GridColumn field="pack_size" title="Pack Size" width="100" cell={(o: GridCellProps) => {
          return (<td>
            <ProductInstanceCell hasInstance={!!o.dataItem.instance} instanceProp={o.dataItem.instance?.pack_size} productProp={o.dataItem.pack_size} />
          </td>)
        }} />
        <GridColumn field="pack_type" title="Pack Type" width="100" cell={(o: GridCellProps) => {
          return (<td>
            <ProductInstanceCell hasInstance={!!o.dataItem.instance} instanceProp={o.dataItem.instance?.pack_type} productProp={o.dataItem.pack_type} />
          </td>)
        }} />
        <GridColumn field="size" title="Size" width="100" cell={(o: GridCellProps) => {
          return (<td><ProductInstanceCell hasInstance={!!o.dataItem.instance} instanceProp={o.dataItem.instance?.size} productProp={o.dataItem.size} /></td>)
        }} />
        {productCatalogProducts[0]?.attributes?.map((attribute, index) => {
          return (
            <GridColumn key={index} field={attribute?.name} filterable={false} title={attribute?.name} width="100" cell={(o: GridCellProps) => {
              const attrib = o.dataItem.attributes?.find((attr: any) => attr.name === attribute?.name);
              return (<td>{displayAttributeValue(attrib.value)}</td>)
            }}
            />
          )
        })}
        <GridColumn field="added_at" width="240" filterCell={RangeFilterCell} title="Added at" />
        <GridColumn field="actions" title="Actions" filterable={false} width="200px" cell={o => (
          <ActionColumns id={o.dataItem.id + "/" + o.dataItem.instance?.id} route={"customer-product-lists/products/" + previewId} onDelete={(id: string) => {
            setDeleteProductId(o.dataItem.id);
            setDeleteInstanceId(o.dataItem.instance?.id);
            setIsDialogOpen(true);
          }} />
        )} />
      </Grid > :
        <FullPageLoader />
      }
    </>
  </div >);
};
