import { useForm } from "react-hook-form";
import { SaveButton } from "components/shared/saveButton";
import usePredefinedClasses from "context/predefinedClassesContext";
import { FormMode } from "models/util";
import { PredefinedClass } from "models/predefined_class";
import { Checkbox, FormControlLabel } from "@mui/material";
import { featureFlags } from "configs/featureFlags";

interface Props {
  mode?: FormMode,
  pc?: PredefinedClass,
  onExit: any
}

const CreatePredefinedClass = (props: Props) => {
  const { editPredefinedClass, createPredefinedClass } = usePredefinedClasses();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      id: props.pc?.id,
      name: props.pc?.name,
      description: props.pc?.description,
      model_list: props.pc?.model_list,
    },
  });

  const onSubmit = (data: any) => {
    if (props.mode === FormMode.Create) {
      data.labels = [];
      createPredefinedClass(data).then((error: any) => {
        if (!error) {
          props.onExit();
        }
      });
    } else {
      editPredefinedClass(data).then((error: any) => {
        if (!error) {
          props.onExit();
        }
      });
    }
  };

  return (
    <div className="row">
      <form
        className={errors.name ? "was-validated" : "needs-validation"}
        onSubmit={handleSubmit((data) => {
          onSubmit(data);
        })}
        noValidate
      >
        <div className="card">
          <div className="card-body">
            <label className="form-label">Name</label>
            <input
              className="form-control"
              {...register("name", { required: true })}
              required
            />
            {errors.name && (
              <div className="invalid-feedback">
                <p className="warning-icon">This field is required.</p>
              </div>
            )}
          </div>
          <div className="card-body">
            <label className="form-label">Description</label>
            <input
              className="form-control"
              {...register("description", { required: false })}
            />
          </div>
        </div>
        { featureFlags.modelLists &&
          <div className="card my-3">
            <div className="card-body">
              <FormControlLabel
                control={
                  <Checkbox
                    {...register("model_list")}
                    defaultChecked={props.pc?.model_list || false}
                  />
                }
                label={
                  <div>
                    <span>Model List</span>
                    <br />
                    <span className="small small-bottom">
                    The "Model List" contains all products associated with a specific model within a single region and category.
                    </span>
                  </div>
                }
              />
            </div>
          </div>
        }
        <div className="bottom-bar">
          <SaveButton />
        </div>
      </form>
    </div>
  );
};

export default CreatePredefinedClass;
