import { useState, useEffect } from 'react';
import { Grid, GridColumn, GridDataStateChangeEvent, GridFilterChangeEvent, GridProps, GridSortChangeEvent } from '@progress/kendo-react-grid';
import '@progress/kendo-theme-default/dist/all.css';
import { gridSettings, initialSort } from "configs/gridSettings";
import usePredefinedClasses from 'context/predefinedClassesContext';
import { ActionColumns } from 'components/grid/action-column';
import { FullPageLoader } from 'components/shared/fullPageLoader';
import { initialDataState } from 'configs/initialDataState';
import { GuidCell } from 'components/grid/GuidCell';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import SWDialog from 'components/shared/dialog';
import Toast from 'components/toaster/toastr';
import ErrorToast from 'components/toaster/errorToastr';
import { RangeFilterCell } from 'components/grid/DateRangeFilterCell';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useSearchPagination } from 'components/hooks/useSearchPagination';
import { featureFlags } from 'configs/featureFlags';

export const PredefinedClassesTable = () => {
  const [dataState, setDataState] = useState(initialDataState);
  const { isLoading, predefinedClasses, meta, getPredefinedClasses, removePredefinedClassById, isSuccess, error, message } = usePredefinedClasses();
  const [filter, setFilter] = useState<CompositeFilterDescriptor>();
  const [deleteId, setDeleteId] = useState<string>("");
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [config, setConfig] = useState<GridProps | any>(gridSettings);
  const [sort, setSort] = useState(initialSort);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchPageNumber, searchPageSize] = useSearchPagination();

  useEffect(() => {
    const d = { ...dataState }
    d.take = searchPageSize;
    d.skip = (searchPageNumber - 1) * searchPageSize;
    d.isInitial = false;
    setDataState(d);
  }, []);


  useEffect(() => {
    if (dataState.isInitial) return;
    const page = dataState.skip / dataState.take + 1;
    const take = String(dataState.take);
    setSearchParams(`?${new URLSearchParams({ pageNumber: String(page), pageSize: take })}`);
    getPredefinedClasses({
      page: page, take: dataState.take, filters: filter?.filters, sort: sort
    })
  }, [dataState])

  const sortChanged = (sort: SortDescriptor[]) => {
    const page = dataState.skip / dataState.take + 1;
    getPredefinedClasses({ page: page, take: dataState.take, filters: filter?.filters, sort: sort })
  }

  const filterChange = (event: GridFilterChangeEvent) => {
    setFilter(event.filter);
  }

  useEffect(() => {
    const getData = setTimeout(() => {
      const page = dataState.skip / dataState.take + 1;
      getPredefinedClasses({ page: page, take: dataState.take, filters: filter?.filters, sort: sort });
    }, 500);

    return () => clearTimeout(getData);
  }, [filter]);

  const removePredefineClass = () => {
    setIsDialogOpen(false);
    removePredefinedClassById(deleteId);
  }

  const assignLabelsButton = (id: string) => {
    return (
      <NavLink to={`/predefined-classes/edit-labels/${id}`} replace={true}>
        <button type="button" className="btn btn-sm btn-secondary">Assign labels</button>&nbsp;&nbsp;
      </NavLink>
    )
  }

  return (<div>
    <SWDialog
      open={isDialogOpen}
      confirmText="Delete"
      text="Are you sure that you want to delete this Predefined Class?"
      onConfirm={() => removePredefineClass()}
      onClose={() => setIsDialogOpen(false)}
    />
    {isSuccess && <Toast message={message} />}
    {error && <ErrorToast message={error} />}
    {predefinedClasses && meta ? <Grid
      {...config}
      data={predefinedClasses}
      total={meta.total_count}
      pageSize={dataState.take}
      filter={filter}
      onFilterChange={filterChange}
      onDataStateChange={(e: GridDataStateChangeEvent) => {
        setDataState(e.dataState as any);
      }}
      skip={meta.page_size * meta.current_page - meta.page_size}
      sort={sort}
      onSortChange={(e: GridSortChangeEvent) => {
        setSort(e.sort);
        sortChanged(e.sort);
      }}
    >
      <GridColumn field="id" title="UUID" width="110" sortable={false} cell={GuidCell} />
      <GridColumn field="name" title="Name" cell={o => (
        <td>
          <NavLink to={`/predefined-classes/${o.dataItem.id}/labels`} replace={true}>
            {o.dataItem.name}
          </NavLink>
        </td>
      )} />
      <GridColumn field="labels_count" title="Labels" filterable={false} />
      { featureFlags.modelLists &&
        <GridColumn field="model_list" title="Model List" filterable={false} />
      }
      <GridColumn field="created_at" width="240" filterCell={RangeFilterCell} title="Created at" />
      <GridColumn field="updated_at" width="240" filterCell={RangeFilterCell} title="Updated at" />
      <GridColumn field="actions" sortable={false} title="Actions" filterable={false} width="300px" cell={o => (
        <ActionColumns id={o.dataItem.id} route="predefined-classes" children={assignLabelsButton(o.dataItem.id)} onDelete={(id: string) => {
          setDeleteId(id);
          setIsDialogOpen(true);
        }} />
      )} />
    </Grid > :
      <FullPageLoader />
    }
  </div >);
};
