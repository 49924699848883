import { GridCellProps } from "@progress/kendo-react-grid";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from "@mui/material/Tooltip";

export const GuidCell = (props: GridCellProps) => {
  const onClick = () => navigator.clipboard.writeText(props.dataItem?.id)
  return (
    <td className="copyCell">
      {props.field && props.dataItem?.id?.substring(0, 8)} &nbsp;
      <Tooltip title="Copy to clipboard">
        <ContentCopyIcon onClick={onClick} className="copyButton" sx={{ fontSize: 14 }} style={{ "cursor": "pointer" }} />
      </Tooltip>
    </td>
  );
}
