import { PredefinedClass } from "models/predefined_class";
import { DEFAULT_ERROR, Meta } from "models/util";

export interface PredefinedClassesState {
  isLoading: boolean;
  predefinedClasses: PredefinedClass[];
  meta?: Meta,
  error?: string;
  getPredefinedClasses?: any
  isSuccess?: boolean,
  message?: string,
  clearActionSuccess?: any;
  removePredefinedClassById?: any;
  editPredefinedClass?: any;
  createPredefinedClass?: any;
  resetPredefinedClass?: any;
  getPredefinedClass?: any;
}

export const initialState: PredefinedClassesState = {
  isLoading: true,
  predefinedClasses: [],
  meta: { current_page: 1, page_size: 20, total_count: 20 },
};

type Action =
  | { type: 'request' }
  | { type: 'success', results: { predefinedClasses: PredefinedClass[], meta: Meta } }
  | { type: 'failure', error?: string }
  | { type: 'actionSuccess', message: string }
  | { type: 'clearActionSuccess' }
  | { type: 'clearActionFailure' }

export function predefinedClassesReducer(state: PredefinedClassesState, action: Action): PredefinedClassesState {
  switch (action.type) {
    case 'request':
      return { ...state, isLoading: true, error: undefined };
    case 'success':
      return { ...state, isLoading: false, predefinedClasses: action.results.predefinedClasses, meta: action.results.meta };
    case 'failure':
      return { ...state, isLoading: false, predefinedClasses: [], error: action.error || DEFAULT_ERROR };
    case 'actionSuccess':
      return { ...state, isSuccess: true, message: action.message, error: undefined }
    case 'clearActionSuccess':
      return { ...state, isSuccess: false, message: undefined }
    case 'clearActionFailure':
      return { ...state, error: undefined }
  }
}
