
import CommonLayout from "components/layouts/common-layout";
import { BrandFamilesTable } from "components/brandFamilies/brandFamiliesTable";
import { BrandFamiliesProvider } from "context/brandsFamiliesContext";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Drawer } from "@mui/material";
import ViewBrandFamily from "./viewBrandFamily";
import axios from "axios";
import EditBrandFamily from "./editBrandFamily";
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddBrandFamily from "./addBrandFamily";
import { FormMode } from "models/util";

interface BrandFamiliessProps {
  mode: FormMode
}

const BrandFamiliesPage = (props: BrandFamiliessProps) => {
  const [drawerTitle, setDrawerTitle] = useState<string>("Brand Family")
  let { editId, previewId } = useParams();
  const [brandFamily, setBrandFamily] = useState<any>({});
  const [mode, setMode] = useState<FormMode>(props.mode)
  const navigate = useNavigate();

  useEffect(() => {
    if (editId) {
      setBrandFamily(null);
      setMode(FormMode.Edit);
      getBrandFamilyById(editId).then((res: any) => {
        setBrandFamily(res.data.data)
      })
    }
  }, [editId])

  useEffect(() => {
    if (previewId) {
      setMode(FormMode.View);
      getBrandFamilyById(previewId).then((res: any) => {
        setBrandFamily(res.data.data)
      })
    }
  }, [previewId])

  useEffect(() => {
    if (mode === FormMode.Create) {
      setDrawerTitle("New Brand Family")
    }
    if (mode === FormMode.Edit) {
      setDrawerTitle("Edit Brand Family")
    }
    if (mode === FormMode.View) {
      setDrawerTitle("Brand Family")
    }
  }, [mode])

  const getBrandFamilyById = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/brand_families/${id}`)
  }

  const onExit = () => {
    setMode(FormMode.None);
    setBrandFamily(undefined);
    navigate("/brand-families", { replace: true })
  }

  return (
    <CommonLayout>
      <h2 className="float-start">Brand Families</h2>
      <button onClick={() => {
        navigate("/brand-families/new", { replace: true })
        setMode(FormMode.Create)
      }} className="btn btn-primary btn-block fw-bolder float-end">
        <AddIcon />New Brand Family
      </button>
      <BrandFamiliesProvider>
        <div className="clearfix"></div>
        <BrandFamilesTable />
        <Drawer
          PaperProps={{ style: { width: '600px' } }}
          anchor={'right'}
          open={mode !== FormMode.None}
          onClose={onExit}
        >
          <section className="sidebar-entity p-4">
            <h2 className="float-start">
              <ArrowBackIcon onClick={onExit} className="primary-col pointer" /><span className="px-2">{drawerTitle}</span>
            </h2>
            <div className="clearfix" />
            {mode === FormMode.View && <ViewBrandFamily brandFamily={brandFamily} />}
            {mode === FormMode.Create && <AddBrandFamily onExit={onExit} />}
            {mode === FormMode.Edit && brandFamily && <EditBrandFamily onExit={onExit} supplier={brandFamily} />}
          </section>
        </Drawer>
      </BrandFamiliesProvider>
    </CommonLayout>
  )
}

export default BrandFamiliesPage;
