import { Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from "@mui/material"

interface dialogProps {
  open: boolean,
  text: string,
  content?: string,
  onClose?: any,
  onConfirm?: any,
  confirmText?: string,
}
const SWDialog = (props: dialogProps) => {
  const confirmText: string = props.confirmText || "Yes";

  return (
    <Dialog
      open={props?.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {props?.text}
      </DialogTitle>
     <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props?.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onConfirm}>{confirmText}</Button>
        <Button onClick={props.onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SWDialog
