export class ProductInstance {
  name?: string | null;
  external_id?: string | null;
  ean?: string | null;
  upc?: string | null;
  size?: string | null;
  pack_size?: string | null;
  pack_type?: string | null;
  region?: string | null;
  brand?: string | null;
  brand_family?: string | null;
  supplier?: string | null;
  classification?: string | null;
  subclassification?: string | null;
  properties?: any | null;

  constructor() {
    this.name = null;
    this.external_id = null;
    this.ean = null;
    this.upc = null;
    this.size = null;
    this.pack_size = null;
    this.pack_type = null;
    this.brand = null;
    this.brand_family = null;
    this.supplier = null;
    this.classification = null;
    this.subclassification = null;
    this.properties = null;
  }
}
