import { ModelList } from "models/model_list";
import { DEFAULT_ERROR, Meta } from "models/util";

export interface ModelListsState {
  isLoading: boolean;
  modelLists: ModelList[];
  meta?: Meta,
  error?: string;
  getModelLists?: any
  isSuccess?: boolean,
  message?: string,
  clearActionSuccess?: any;
  getModelList?: any;
  searchModelLists?: any;
}

export const initialState: ModelListsState = {
  isLoading: true,
  modelLists: [],
  meta: { current_page: 1, page_size: 20, total_count: 20 },
};

type Action =
  | { type: 'request' }
  | { type: 'success', results: { modelLists: ModelList[], meta: Meta } }
  | { type: 'failure', error?: string }
  | { type: 'actionSuccess', message: string }
  | { type: 'clearActionSuccess' }
  | { type: 'clearActionFailure' }

export function modelListsReducer(state: ModelListsState, action: Action): ModelListsState {
  switch (action.type) {
    case 'request':
      return { ...state, isLoading: true, error: undefined };
    case 'success':
      return { ...state, isLoading: false, modelLists: action.results.modelLists, meta: action.results.meta };
    case 'failure':
      return { ...state, isLoading: false, modelLists: [], error: action.error || DEFAULT_ERROR };
    case 'actionSuccess':
      return { ...state, isSuccess: true, message: action.message, error: undefined }
    case 'clearActionSuccess':
      return { ...state, isSuccess: false, message: undefined }
    case 'clearActionFailure':
      return { ...state, error: undefined }
  }
}
