import axios from "axios";
import { OtherObject } from "models/other_object";
import { createContext, useReducer, useContext, ReactNode } from "react";
import { otherObjectsReducer, initialState } from "reducers/otherObjectsReducer";
import { clearActionFailure, clearActionSuccess, convertFiltersToQueryString, Meta, setFailure, setLoading, generateSortQueryParam, GetEntitiesProps } from "models/util";
import { processDateObjects } from "shared/functions/processDateObjects";
import { useNavigate } from "react-router-dom";

const OtherObjectContext = createContext(initialState);

interface Props {
  children?: ReactNode
}

export const OtherObjectsProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(otherObjectsReducer, initialState);
  const navigate = useNavigate();

  const removeOtherObjectById = (id: string | undefined) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/other_objects/${id}`).then(res => {
      dispatch({ type: "actionSuccess", message: "Other Object has been deleted!" })
      setTimeout(() => getOtherObjects({ page: 1, take: 20 }), 2000);
      setTimeout(() => clearActionSuccess(dispatch), 5000)
    });
  }

  const getOtherObjects = ({ page = 1, take = 20, filters = [], sort = [] }: GetEntitiesProps) => {
    const f = convertFiltersToQueryString(filters);
    setLoading();
    axios.get(`${process.env.REACT_APP_API_URL}/other_objects?page=${page}&items_per_page=${take}${f}${generateSortQueryParam(sort)}`)
      .then(res => {
        setOtherObjects(processDateObjects(res.data.data), res.data.meta)
      },
        () => setFailure("Error getting other objects"))
  }

  const editOtherObject = (otherObject: OtherObject) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/other_objects/${otherObject.id}`, otherObject)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Other Object has been updated!" })
        getOtherObjects({ page: 1, take: 20 });
        navigate("/other-objects", { replace: true })
        setTimeout(() => clearActionSuccess(dispatch), 5000)

      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(dispatch), 5000)
          return error;
        })
  }

  const createOtherObject = (otherObject: OtherObject) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/other_objects`, otherObject)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Other Object has been created!" })
        getOtherObjects({ page: 1, take: 20 });
        navigate("/other-objects", { replace: true })
        setTimeout(() => clearActionSuccess(dispatch), 5000)

      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(dispatch), 5000)
          return error;
        })
  }

  const setOtherObjects = (otherObjects: OtherObject[], meta: Meta) => {
    dispatch({
      type: "success",
      results: { otherObjects: otherObjects, meta },
    });
  };

  const setLoading = () => {
    dispatch({
      type: "request",
    });
  }

  const setFailure = (errorMessage: string) => {
    dispatch({
      type: "failure",
      error: errorMessage
    });
  }

  const value = {
    isLoading: state.isLoading,
    error: state.error,
    otherObjects: state.otherObjects,
    meta: state.meta,
    setOtherObjects: setOtherObjects,
    getOtherObjects: getOtherObjects,
    editOtherObject: editOtherObject,
    createOtherObject: createOtherObject,
    removeOtherObjectById: removeOtherObjectById,
    setLoading,
    setFailure
  };
  return <OtherObjectContext.Provider value={value}>{children}</OtherObjectContext.Provider>;
};

const useOtherObject = () => {
  const context = useContext(OtherObjectContext);

  if (context === undefined) {
    throw new Error("useOtherObject must be used within OtherObjectContext");
  }

  return context;
};

export default useOtherObject;
