import { useSearchParams } from 'react-router-dom';
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from 'shared/constants/constants';

export const useSearchPagination = () => {
  const [searchParams] = useSearchParams();

  const searchPageNumber = parseInt(searchParams.get('pageNumber') || DEFAULT_PAGE_NUMBER, 10);
  const searchPageSize = parseInt(searchParams.get('pageSize') || DEFAULT_PAGE_SIZE, 10);

  return [searchPageNumber, searchPageSize]
}
