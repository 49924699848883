import React from 'react';
import { Box, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import MainMenu from 'components/layouts/main-menu';
import useAuthentication from 'context/authenticationContext';

const ForbiddenPage = () => {
  return (
    <Box>
      <CssBaseline />
      <MainMenu />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        bgcolor="#f5f5f5"
      >
        <Typography variant="h5" color="textSecondary">
          Oops! You don't have permission to access this page.
        </Typography>
      </Box>
    </Box>
  );
};

export default ForbiddenPage;
