import axios from "axios";
import { Label } from "models/label";
import { createContext, useReducer, useContext, ReactNode, useRef, RefObject } from "react";
import { labelsReducer, initialState } from "reducers/labelsReducer";
import { convertFiltersToQueryString, Meta, generateSortQueryParam, GetEntitiesProps } from "models/util";
import { processDateObjects } from "shared/functions/processDateObjects";
import { useNavigate } from "react-router-dom";

const LabelsContext = createContext(initialState);

interface Props {
  children?: ReactNode
}

export const LabelsProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(labelsReducer, initialState);
  const navigate = useNavigate();
  let axiosCancelToken = useRef<any>(null);
  const removeLabelById = (id: string | undefined) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/labels/${id}`).then(res => {
      dispatch({ type: "actionSuccess", message: "Label has been deleted!" })
      setTimeout(() => getLabels({ page: 1, take: 20 }), 2000);
      setTimeout(() => clearActionSuccess(), 5000)
    });
  }

  const getLabels = ({ page = 1, take = 20, filters = [], sort = [] }: GetEntitiesProps) => {

    const f = convertFiltersToQueryString(filters);
    setLoading();
    if (axiosCancelToken.current) {
      axiosCancelToken.current.cancel();
      console.warn("request cancelled");
    }
    axiosCancelToken.current = axios.CancelToken.source();
    axios.get(`${process.env.REACT_APP_API_URL}/labels/?page=${page}&items_per_page=${take}${f}${generateSortQueryParam(sort)}`, {
      // specify the cancelToken to use
      // for cancelling the request
      cancelToken: axiosCancelToken.current.token
    })
      .then(res => {
        if (!res || !res.data) return;
        setLabels(processDateObjects(res.data.data), res.data.meta)
      },
        () => setFailure("Error getting labels"))
  }

  const setLabels = (labels: Label[], meta: Meta) => {
    dispatch({
      type: "success",
      results: { labels: labels, meta },
    });
  };

  const createLabel = (label: Label) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/labels`, label)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Label has been created!" })
        setTimeout(() => { getLabels({ page: 1, take: 20 }) }, 500)
        navigate("/labels", { replace: true })
        setTimeout(() => clearActionSuccess(), 5000)

      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(), 5000)
          return error;
        })
  }

  const editLabel = (label: Label, id: string) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/labels/${id}`, label)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Label has been updated!" });
        setTimeout(() => { getLabels({ page: 1, take: 20 }) }, 500);
        navigate("/labels", { replace: true })
        setTimeout(() => clearActionSuccess(), 5000)

      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(), 5000)
          return error;
        })
  }
  const clearActionSuccess = () => {
    dispatch({ type: "clearActionSuccess" })
  }
  const clearActionFailure = () => {
    dispatch({ type: "clearActionFailure" })
  }


  const setLoading = () => {
    dispatch({
      type: "request",
    });
  }

  const setFailure = (errorMessage: string) => {
    dispatch({
      type: "failure",
      error: errorMessage
    });
  }

  const value = {
    isLoading: state.isLoading,
    error: state.error,
    labels: state.labels,
    meta: state.meta,
    message: state.message,
    isSuccess: state.isSuccess,
    createLabel: createLabel,
    editLabel: editLabel,
    setBrands: setLabels,
    getLabels: getLabels,
    removeLabelById: removeLabelById,
    setLoading,
    setFailure
  };
  return <LabelsContext.Provider value={value}>{children}</LabelsContext.Provider>;
};

const useLabels = () => {
  const context = useContext(LabelsContext);

  if (context === undefined) {
    throw new Error("useLabels must be used within LabelsContext");
  }

  return context;
};

export default useLabels;
