import { Box, Modal } from "@mui/material";
import CommonLayout from "components/layouts/common-layout";
import { LabelsTable } from "components/labels/labelsTable";
import { LabelsProvider } from "context/labelsContext";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from "axios";
import EditLabels from "./editLabels";
import ViewLabels from "./viewLabels";
import { Drawer } from "@mui/material";
import { FormMode } from "models/util";
import AddIcon from '@mui/icons-material/Add';
import { useFileUpload } from "hooks/useFileUpload";
import { DropZone } from "components/shared/dropZone";
import { BootstrapButton } from "components/shared/bootstrapButton";
import { modalStyle } from "components/shared/modalStyles";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DescriptionIcon from '@mui/icons-material/Description';

interface LabelProps {
  mode: FormMode
}

const LabelsPage = (props: LabelProps) => {
  const [drawerTitle, setDrawerTitle] = useState<string>("Labels")
  let { editId, previewId } = useParams();
  const [label, setLabel] = useState<any>({});
  const [mode, setMode] = useState<FormMode>(props.mode)
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [file, setFile] = useState<Blob>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadFile] = useFileUpload();

  useEffect(() => {
    if (editId) {
      setLabel(null);
      setMode(FormMode.Edit);
      getLabelsById(editId).then((res: any) => {
        setLabel(res.data.data)
      })
    }
  }, [editId])

  useEffect(() => {
    if (previewId) {
      setMode(FormMode.View);
      getLabelsById(previewId).then((res: any) => {
        setLabel(res.data.data)
      })
    }
  }, [previewId])

  useEffect(() => {
    if (mode === FormMode.Create) {
      setDrawerTitle("New Label")
    }
    else if (mode === FormMode.Edit) {
      setDrawerTitle("Edit Label")
    }
    else {
      setDrawerTitle("Label")
    }
  }, [mode])

  const getLabelsById = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/labels/${id}`)
  }

  const onExit = () => {
    setMode(FormMode.None);
    setLabel(null);
    navigate("/labels", { replace: true })
  }

  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => {
    setFile(undefined);
    setIsModalOpen(false);
  }

  const onFileAdded = (file: any) => {
    setFile(file)
  }

  const handleUpload = () => {
    setIsUploading(true);
    const options = {
      import_type: "labels_tags",
      import_strategies: [],
      context: {}
    }
    uploadFile(file, options).then(() => {
      setIsUploading(false);
    })
  }

  return (
    <CommonLayout>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <h2 className="float-start normal-header">
            <ClearOutlinedIcon onClick={handleClose} className="primary-col pointer" style={{ marginTop: "-4px" }} />
            <span style={{ marginLeft: "40px" }}>Assigns tags to labels via CSV</span>
          </h2>
          <div className="clearfix"></div>
          <DropZone onFileAdded={onFileAdded} />
          <div className="card my-3">
            <div className="card-body">
              <DescriptionIcon className="primary-col float-left" style={{ fontSize: 30 }} />
              <p className="m-0 float-left middle-par">Example of assigning tags to labels</p>
              <a type="button" href={`${process.env.REACT_APP_API_BASE_URL}/imports/labels_tags.csv`} className="btn btn-sm btn-secondary float-right">Download</a>
            </div>
          </div>
          {!isUploading && !!file && <>
            <BootstrapButton variant="contained" onClick={handleUpload}>
              <FileUploadIcon fontSize='small' />
              <label>Upload</label>
            </BootstrapButton>
          </>}
          {isUploading && <>
            <BootstrapButton disabled variant="contained" onClick={handleUpload}>
              <label>In progress...</label>
            </BootstrapButton>
          </>}

        </Box>
      </Modal>
      <h2 className="float-start">Labels</h2>
      <button onClick={handleOpen} className="btn btn-secondary btn-block fw-bolder float-end floating-button-right">
        <AddIcon />Assign tags via CSV
      </button>
      <LabelsProvider>
        <div className="clearfix"></div>
        <LabelsTable />
        <Drawer
          PaperProps={{ style: { width: '600px' } }}
          anchor={'right'}
          open={mode !== FormMode.None}
          onClose={onExit}
        >
          <section className="sidebar-entity p-4">
            <h2 className="float-start">
              <ArrowBackIcon onClick={onExit} className="primary-col pointer" /><span className="px-2">{drawerTitle}</span>
            </h2>
            <div className="clearfix" />
            {mode === FormMode.View && <ViewLabels label={label} />}
            {mode === FormMode.Edit && label && <EditLabels onExit={onExit} label={label} />}
          </section>
        </Drawer>

      </LabelsProvider>
    </CommonLayout>
  )
}

export default LabelsPage;
