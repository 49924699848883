import axios from "axios";
import { Classification } from "models/classification";
import { createContext, useReducer, useContext, ReactNode } from "react";
import { classificationsReducer, initialState } from "reducers/classificationsReducer";
import { convertFiltersToQueryString, Meta, generateSortQueryParam, GetEntitiesProps } from "models/util";
import { processDateObjects } from "shared/functions/processDateObjects";

const ClassificationsContext = createContext(initialState);

interface Props {
  children?: ReactNode
}

export const ClassificationsProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(classificationsReducer, initialState);

  const removeClassificationById = (id: string | undefined) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/classifications/${id}`).then(res => {
      dispatch({ type: "actionSuccess", message: "Classification has been deleted!" })
      setTimeout(() => getClassifications({ page: 1, take: 20 }), 2000);
      setTimeout(() => clearActionSuccess(), 5000)
    });
  }

  const getClassifications = ({ page = 1, take = 20, filters = [], sort = [] }: GetEntitiesProps) => {
    const f = convertFiltersToQueryString(filters);
    setLoading();
    axios.get(`${process.env.REACT_APP_API_URL}/classifications/?page=${page}&items_per_page=${take}${f}${generateSortQueryParam(sort)}`)
      .then(res => {
        setClassifications(processDateObjects(res.data.data), res.data.meta)
      },
        () => setFailure("Error getting classifications"))
  }

  const clearActionSuccess = () => {
    dispatch({ type: "clearActionSuccess" })
  }
  const clearActionFailure = () => {
    dispatch({ type: "clearActionFailure" })
  }

  const setClassifications = (classifications: Classification[], meta: Meta) => {
    dispatch({
      type: "success",
      results: { classifications: classifications, meta },
    });
  };

  const createClassifications = (classi: Classification) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/classifications`, classi)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Classification has been created!" })

        setTimeout(() => {
          getClassifications({ page: 1, take: 20 });
        }, 1000);
        setTimeout(() => clearActionSuccess(), 5000)

      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(), 5000)
          return error;
        })
  }

  const editClassifications = (classi: Classification) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/classifications/${classi.id}`, classi)
      .then(res => {
        dispatch({ type: "actionSuccess", message: "Classification has been updated!" })
        setTimeout(() => getClassifications({ page: 1, take: 20 }), 2000);
        setTimeout(() => clearActionSuccess(), 5000)

      },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message })
          } else {
            dispatch({ type: "failure" })
          }
          setTimeout(() => clearActionFailure(), 5000)
          return error;
        })
  }

  const setLoading = () => {
    dispatch({
      type: "request",
    });
  }

  const setFailure = (errorMessage: string) => {
    dispatch({
      type: "failure",
      error: errorMessage
    });
  }

  const value = {
    isLoading: state.isLoading,
    error: state.error,
    classifications: state.classifications,
    meta: state.meta,
    setClassifications: setClassifications,
    getClassifications: getClassifications,
    setLoading,
    setFailure,
    createClassifications: createClassifications,
    editClassifications: editClassifications,
    removeClassificationById: removeClassificationById,
    isSuccess: state.isSuccess,
    message: state.message
  };
  return <ClassificationsContext.Provider value={value}>{children}</ClassificationsContext.Provider>;
};

const useClassifications = () => {
  const context = useContext(ClassificationsContext);

  if (context === undefined) {
    throw new Error("useClassifications must be used within ClassificationsContext");
  }

  return context;
};

export default useClassifications;
