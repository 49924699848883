import { useForm } from "react-hook-form";
import { SaveButton } from "components/shared/saveButton";
import useOtherObject from "context/otherObjectsContext";
import { Label } from "models/label";

const EditOtherObject = (props: any) => {
  const { editOtherObject } = useOtherObject();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: props.otherObject?.name,
      labels: props.otherObject?.labels.map((l: Label) => l.id)
    }
  });

  return (
    <form
      className={errors.name ? "was-validated" : "needs-validation"}
      onSubmit={handleSubmit((data: any) => {
        data.id = props.otherObject.id;
        editOtherObject(data).then((error: any) => {
          if (!error) {
            props.onExit()
          }
        }
        )
      })}
      noValidate
    >
      <div className="card">
        <div className="card-body">
          <label className="form-label">Name</label>
          <input className="form-control"
            {...register("name", { required: true })}
            required
          />
          {errors.name && <div className="invalid-feedback">
            <p className="warning-icon">This field is required.</p>
          </div>}
        </div>
      </div>
      <SaveButton />
    </form >
  );
}

export default EditOtherObject;
