import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { type HTMLInputTypeAttribute } from 'react';

// Interface definitions
interface ProductAttribute {
  id: string | null;
  value: string | string[] | null;
  name: string;
  data_type: string;
  attribute_definition_id: string;
}

interface GroupedAttribute {
  group_name: string;
  data: ProductAttribute[];
}

interface CustomAttributeFormProps {
  initialData: ProductAttribute[] | GroupedAttribute[];
  onChange: (data: any) => void;
}

const FieldComponent = (attribute: ProductAttribute, handleChange: any, type: HTMLInputTypeAttribute) => {
  const [inputValue, setInputValue] = useState(attribute.value as string || '');
  const handleValueChange = (value: string) => {
    setInputValue(value);
    attribute.value = value;
    handleChange(attribute, value);
  };

  return (
    <div className="row align-items-center">
      <div className="col-12">
        <Autocomplete
          key={attribute.name}
          freeSolo
          value={inputValue}
          inputValue={inputValue}
          onInputChange={(_, newValue) => {
            handleValueChange(newValue || '');
          }}
          options={[]}
          sx={{ width: 380, maxWidth: '100%', }}
          renderInput={(params) => (
            <TextField
              {...params}
              type={type}
              placeholder={attribute.data_type}
              size="small"
              fullWidth
            />
          )}
        />
      </div>
    </div>
  );
};

const BooleanSelectFieldComponent = (attribute: ProductAttribute, handleChange: any) => {
  const handleValueChange = (value: string) => {
    attribute.value = value;
    handleChange(attribute, value);
  };

  const options = ['true', 'false'];
  const [inputValue, setInputValue] = useState(attribute.value as string || '');
  return (
    <div className="row align-items-center">
      <div className="col-12">
        <Autocomplete
          key={attribute.name}
          value={inputValue}
          onChange={(_, newValue) => {
            handleValueChange(newValue || '');
          }}
          inputValue={inputValue}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={options}
          renderInput={(params) => (
            <TextField {...params} placeholder={attribute.data_type} size="small" fullWidth />
          )}
        />
      </div>
    </div>
  );
};

const ArraySelectFieldComponent = (attribute: ProductAttribute, handleChange: any) => {
  const [chipInputValue, setChipInputValue] = useState('');
  const [chips, setChips] = useState<string[]>(Array.isArray(attribute.value) ? attribute.value : []);

  useEffect(() => {
    if (Array.isArray(attribute.value)) {
      setChips(attribute.value);
    }
  }, [attribute.value]);

  const handleAddChip = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Tab' && chipInputValue.trim() !== '') {
      e.preventDefault();
      if (!chips.includes(chipInputValue.trim())) {
        const newChips = [...chips, chipInputValue.trim()];
        setChips(newChips);
        handleArrayValueChange(newChips);
        setChipInputValue('');
      }
    }
  };

  const handleDeleteChip = (chipToDelete: string) => {
    const newChips = chips.filter((chip) => chip !== chipToDelete);
    setChips(newChips);
    handleArrayValueChange(newChips);
  };

  const handleArrayValueChange = (newValue: string[]) => {
    attribute.value = newValue;
    handleChange(attribute, newValue);
  };

  return (
    <div className="row align-items-center">
      <div className="col-12">
        <Stack className="chips-container" direction="row" sx={{ flexWrap: 'wrap' }}>
          {chips.map((chip, index) => (
            <Chip key={index} label={chip} onDelete={() => handleDeleteChip(chip)} />
          ))}
        </Stack>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Add new element and push tab"
          value={chipInputValue}
          onChange={(e) => setChipInputValue(e.target.value)}
          onKeyDown={handleAddChip}
          fullWidth
          sx={{ mt: 1, width: 380, maxWidth: '100%'}}
        />
      </div>
    </div>
  );
};

export const CustomAttributeForm: React.FC<CustomAttributeFormProps> = ({ initialData, onChange }) => {
  const [attributes, setAttributes] = useState(initialData);

  useEffect(() => {
    onChange(attributes);
  }, [attributes, onChange]);

  const handleStringValueChange = (attribute: ProductAttribute, value: string) => {
    const updatedAttributes = setNewAttributeValue([...attributes], attribute.name, value);
    handleChange(updatedAttributes);
  };

  const handleArrayValueChange = (attribute: ProductAttribute, value: string[]) => {
    const updatedAttributes = setNewAttributeValue([...attributes], attribute.name, value);
    handleChange(updatedAttributes);
  };

  const setNewAttributeValue = (attributes: any[], attributeName: string, value: string | string[]) => {
    attributes.forEach((attr) => {
      if ('data' in attr) {
        attr.data.forEach((item: ProductAttribute) => {
          if (item.name === attributeName) {
            item.value = value;
          }
        });
      } else if (attr.name === attributeName) {
        attr.value = value;
      }
    });
    return attributes;
  };

  const handleChange = (updatedAttributes: any[]) => {
    setAttributes(updatedAttributes);
    onChange(updatedAttributes);
  };

  const fieldForType = (attribute: ProductAttribute) => {
    switch (attribute.data_type) {
      case 'string':
      case 'numeric':
        return FieldComponent(attribute, handleStringValueChange, attribute.data_type === 'string' ? 'text' : 'number');
      case 'boolean':
        return BooleanSelectFieldComponent(attribute, handleStringValueChange);
      case 'string_array':
        return ArraySelectFieldComponent(attribute, handleArrayValueChange);
      default:
        return null;
    }
  };

  return (
    <div>
      {Array.isArray((attributes as GroupedAttribute[])[0]?.data) ? (
        (attributes as GroupedAttribute[]).map((group, groupIndex) => (
          <div key={group.group_name}>
            <h5 className="group-title">{group.group_name}</h5>
            <div className="grouped-attributes mb-3">
              {group.data.map((attribute, attrIndex) => (
                <div key={attribute.attribute_definition_id} className="attribute-item">
                  <div className="attribute-content">
                    <label className="attribute-name">{attribute.name}</label>
                    {fieldForType(attribute)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))
      ) : (
        (attributes as ProductAttribute[]).map((attribute, attrIndex) => (
          <div key={attribute.attribute_definition_id} className="attribute-item">
            <div className="attribute-content">
              <label className="attribute-name">{attribute.name}</label>
              {fieldForType(attribute)}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default CustomAttributeForm;
