import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { SaveButton } from "components/shared/saveButton";
import useCustomAttributes from "context/customAttributesContext";
import { Select, MenuItem, SelectChangeEvent } from "@mui/material";

const CreateCustomAttribute = (props: any) => {
  const { createCustomAttribute } = useCustomAttributes();
  const [dataType, setDataType] = useState<string>("");
  // TODO: enable all data types when data validation will be implemented.
  // const dataTypes = ["boolean", "boolean_array", "float_array", "integer_array", "numeric", "string", "string_array"]
  const dataTypes = ["boolean", "numeric", "string"]
  let { catalogId } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      data_type: "",
      description: ""
    }
  });

  const handleChange = (event: SelectChangeEvent) => {
    setDataType(event.target.value)
  };

  const onSubmit = (data: any) => {
    createCustomAttribute(data, catalogId).then((error: any) => {
      if (!error) {
        props.onExit();
      }
    });
  }

  return (
    <div className="row">
      <form
        className={errors.name ? "was-validated" : "needs-validation"}
        onSubmit={handleSubmit((data) => {
          onSubmit(data);
        })}
        noValidate
      >
        <div className="card">
          <div className="card-body">
            <label className="form-label">Name</label>
            <input className="form-control"
              {...register("name", { required: true })}
              required
            />
            {errors.name && <div className="invalid-feedback">
              <p className="warning-icon">This field is required.</p>
            </div>}
          </div>
          <div className="card-body">
            <label className="form-label">Data Type</label>
              <Select 
                className="form-control mui-select-small"
                size="small"
                value={dataType}
                onChange={handleChange}
                inputProps={{...register("data_type", { required: true })}}
              >
              {dataTypes.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            {errors.data_type && <div className="invalid-feedback">
              <p className="warning-icon">This field is required.</p>
            </div>}
            <p></p>
          </div>
        </div>
        <div className="bottom-bar">
          <SaveButton />
        </div>
      </form>
    </div>
  );
}

export default CreateCustomAttribute;
