import { useState, useEffect } from 'react';
import { Grid, GridColumn, GridDataStateChangeEvent, GridFilterChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import '@progress/kendo-theme-default/dist/all.css';
import { gridSettings, initialSort } from "configs/gridSettings";
import { NameValueCell } from 'components/grid/NameValueCell';
import useBrandCategories from 'context/brandCategoriesContext';
import { ActionColumns } from 'components/grid/action-column';
import { FullPageLoader } from 'components/shared/fullPageLoader';
import { initialDataState } from 'configs/initialDataState';
import { LabelCell } from 'components/grid/label-cell';
import { NavLink, useSearchParams } from 'react-router-dom';
import Toast from 'components/toaster/toastr';
import ErrorToast from 'components/toaster/errorToastr';
import { GuidCell } from 'components/grid/GuidCell';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import SWDialog from 'components/shared/dialog';
import { RangeFilterCell } from 'components/grid/DateRangeFilterCell';
import { useSearchPagination } from 'components/hooks/useSearchPagination';

export const BrandCategoriesTable = () => {
  const [dataState, setDataState] = useState(initialDataState);
  const { brandCategories, meta, getBrandCategories, message, error, isSuccess, removeBrandCategoryById } = useBrandCategories();
  const [filter, setFilter] = useState<CompositeFilterDescriptor | any>(undefined);
  const [deleteId, setDeleteId] = useState<string>("");
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [sort, setSort] = useState<Array<SortDescriptor>>(initialSort);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchPageNumber, searchPageSize] = useSearchPagination();

  useEffect(() => {
    if (dataState.isInitial) return;
    const page = dataState.skip / dataState.take + 1;
    const take = String(dataState.take);
    setSearchParams(`?${new URLSearchParams({ pageNumber: String(page), pageSize: take })}`)
    getBrandCategories({
      page: page, take: dataState.take, filters: filter?.filters, sort: sort
    })
  }, [dataState])

  const filterChange = (event: GridFilterChangeEvent) => {
    setFilter(event.filter);
  }

  const sortChanged = (sort: SortDescriptor[]) => {
    const page = dataState.skip / dataState.take + 1;
    getBrandCategories({ page: page, take: dataState.take, filters: filter?.filters, sort: sort })
  }

  useEffect(() => {
    if (filter === undefined) return;
    const getData = setTimeout(() => {
      const page = dataState.skip / dataState.take + 1;
      getBrandCategories({ page: page, take: dataState.take, filters: filter?.filters, sort: sort });
    }, 500);

    return () => clearTimeout(getData);
  }, [filter]);

  useEffect(() => {
    const d = { ...dataState }
    d.take = searchPageSize;
    d.skip = (searchPageNumber - 1) * searchPageSize;
    d.isInitial = false;
    setDataState(d);
  }, []);

  const removeCategory = () => {
    setIsDialogOpen(false);
    removeBrandCategoryById(deleteId);
  }

  return (<div>
    <SWDialog
      open={isDialogOpen}
      text="Are you sure that you want to delete this Brand Category?"
      confirmText="Delete"
      onConfirm={() => removeCategory()}
      onClose={() => setIsDialogOpen(false)}
    />
    {isSuccess && <Toast message={message} />}
    {error && <ErrorToast message={error} />}
    {
      brandCategories && meta ? <Grid
        {...gridSettings}
        data={brandCategories}
        total={meta.total_count}
        pageSize={dataState.take}
        filter={filter}
        onFilterChange={filterChange}
        onDataStateChange={(e: GridDataStateChangeEvent) => {
          setDataState(e.dataState as any);
        }}
        skip={meta.page_size * meta.current_page - meta.page_size}
        sort={sort}
        onSortChange={(e: GridSortChangeEvent) => {
          setSort(e.sort);
          sortChanged(e.sort);
        }}
      >
        <GridColumn field="id" width="110" title="UUID" sortable={false} cell={GuidCell} />
        <GridColumn field="name" minResizableWidth={200} sortable={false} title="Name" cell={o => (
          <td>
            <NavLink to={`/brand-categories/${o.dataItem.id}`} replace={true}>
              {o.dataItem.name}
            </NavLink>
            <br />
          </td>
        )} />

        <GridColumn field="labels" minResizableWidth={200} title="Labels" cell={LabelCell} />
        <GridColumn field="brand" minResizableWidth={200} title="Brand" cell={NameValueCell} />
        <GridColumn field="category" minResizableWidth={200} title="Category" cell={NameValueCell} />
        <GridColumn field="created_at" width={240} filterCell={RangeFilterCell} title="Created at" />
        <GridColumn field="updated_at" width={240} filterCell={RangeFilterCell} title="Updated at" />
        <GridColumn field="actions" sortable={false} filterable={false} title="Actions" width="220px" cell={o => (
          <ActionColumns id={o.dataItem.id} route="brand-categories" onDelete={(id: string) => {
            setDeleteId(id);
            setIsDialogOpen(true);
          }} />
        )} />
      </Grid > :
        <FullPageLoader />
    }
  </div>);
};
