export const initialDataState = {
  sort: [
    {
      field: "created_at",
      dir: "asc",
    },
  ],
  take: 20,
  skip: 0,
  isInitial: true
};
