import { Label } from "models/label";
import { ReactNode } from "react";
import SingleLabel from "./singleLabel";

interface Props {
  labelList: Label[];
  selectedLabels: string[];
  onSelected: any;
  onUnselected: any;
  children?: ReactNode
}

const LabelListView = (props: Props) => {
  const labelChanged = (l: any) => {
    if (l.checked) {
      props.onSelected(l.id);
    }
    else {
      props.onUnselected(l.id);
    }
  }

  return (
    <div className="list-view ml-2">
      {props.children}
      <div className="card my-3">
        <div className="label-list-scrollable">
          <div className="label-list-single-label">
            {props.labelList?.map((label: Label) => (
              <SingleLabel isSelected={props?.selectedLabels.some(l => l === label?.id)} key={label?.id} label={label} onLabelChange={labelChanged} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LabelListView;
