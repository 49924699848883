import React, { useState } from "react";
import { useForm } from "react-hook-form";
import useProductCatalogProducts from "context/productCatalogProductsContext";
import { SaveButton } from "components/shared/saveButton";
import { useParams } from "react-router-dom";
import SmartEdit from "./smartEdit";
import { ProductInstance } from "models/product_instance";
import { CustomAttributeForm } from "./customAttributes";
import { ProductAttribute } from '../../models/product';

const EditProductInstance = (props: any) => {
  const { editProductCatalogProduct, createProductCatalogProduct, editAttributeValue, createAttributeValue } = useProductCatalogProducts();
  let { previewId } = useParams();
  const [data, setData] = useState({});
  const [customAttributes, setCustomAttribtues] = useState([]);
  const [newInstance, setNewInstance] = useState<ProductInstance | null>(props.product.instance ? props.product.instance: null);
  const [isPristine, setIsPristine] = useState<boolean>(true);

  const handleChange = (newData: { [key: string]: string }) => {
    setData(newData as any);
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue
  } = useForm({
    defaultValues: {
      id: props.product.instance?.id || undefined,
      name: props.product.instance?.name || props.product.name || "",
      external_id: props.product.instance?.external_id,
      ean: props.product.instance?.ean || props.product.ean || "",
      upc: props.product.instance?.upc || props.product.upc || "",
      brand: props.product.instance?.brand || props.product.brand?.name || "",
      brand_family: props.product.instance?.brand_family || props.product.brand_family?.name || "",
      classification: props.product.instance?.classification || props.product.classification?.name || "",
      subclassification: props.product.instance?.subclassification || props.product.subclassification?.name || "",
      supplier: props.product.instance?.supplier || props.product.supplier?.name || "",
      size: props.product.instance?.size || props.product.size || "",
      pack_size: props.product.instance?.pack_size || props.product.pack_size || "",
      pack_type: props.product.instance?.pack_type || props.product.pack_type || "",
      region: props.product.instance?.region || props.product.region || "",
      properties: props.product.instance?.properties || {},
      attributes: props.product.attributes || []
    }
  });

  const setValueStrategy = (name: string, value: string, ignoreSetValue = true) => {
    if (!props.product.instance) {
      const _newInstance: any = newInstance ? { ...newInstance } : new ProductInstance();
      _newInstance[name] = value;
      setNewInstance(_newInstance)
    }
    else {
      if (newInstance) {
        const _newInstance = { ...newInstance, [name]: value }
        setNewInstance(_newInstance)
      }
      else {
        const _newInstance = { ...props.product.instance, [name]: value }
        setNewInstance(_newInstance)

      }
    }
    setIsPristine(false);
    if (!ignoreSetValue) {
      setValue(name as any, value);
    }
  }

  const restoreValue = (name: string, val: any) => {
    setValue(name as any, val);
    setIsPristine(false);
    let _newInstance;
    if (newInstance) {
      _newInstance = { ...newInstance, [name]: null }
    } else {
      _newInstance = { ...props.product.instance, [name]: null }
    }
    setNewInstance(_newInstance)
  }

  const properties = watch("properties");
  const attributes = watch("attributes");

  const handleCustomAttributes = (catalogId: string, attrs: ProductAttribute[]) => {
    const productId = props.product.id
    attrs.forEach((attr: ProductAttribute) => {
      const currentAttributeValue = props.product.attributes.find((a: ProductAttribute) => a.name === attr.name)?.value
      if (attr.id) {
        handleCustomAttributeUpdate(catalogId, productId, currentAttributeValue, attr)
      } else {
        handleCustomAttributeCreate(catalogId, productId, currentAttributeValue, attr)
      }
    })
  }

  const handleCustomAttributeUpdate = (catalogId: string, productId: string, currentValue: string | string[], attr: ProductAttribute) => {
    if (JSON.stringify(attr.value) !== JSON.stringify(currentValue)) {
      editAttributeValue(catalogId, productId, attr)
    }
  }

  const handleCustomAttributeCreate = (catalogId: string, productId: string, currentValue: string | string[], attr: ProductAttribute) => {
    if (JSON.stringify(attr.value) !== JSON.stringify(currentValue)) {
      createAttributeValue(catalogId, productId, attr)
    }
  }

  return (props.product &&
    <form
      className={errors.name ? "was-validated" : "needs-validation"}
      onSubmit={handleSubmit((d) => {
      
        let _data: any;
        if (isPristine && !newInstance) {
          _data = new ProductInstance();
          _data.id = d.id;
          _data.properties = data;
          _data.external_id = d.external_id;
        } else {
          if (newInstance) {
            _data = { ...newInstance, external_id: d.external_id, properties: { ...data } }
          } else {
            _data = { ...d, properties: { ...data } }
          }
        }
        if (typeof (previewId) === 'string') {
          handleCustomAttributes(previewId, customAttributes)
        }

        const method = d.id ? editProductCatalogProduct : createProductCatalogProduct
        method(_data, previewId, props.product.id).then((error: any) => {
          if (!error) {
            props.onExit()
          }
        }
        )

      })}
      noValidate
    >
      <div className="card">
        <div className="card-body">
          <label className="form-label">Name</label>
          <SmartEdit
            instanceValue={props.product.instance?.name}
            productValue={props.product.name}
            onValueChanged={(v: any) => setValueStrategy("name", v)}
            onRestore={(v: any) => restoreValue("name", props.product.name)}
            register={register("name", { required: true })}
          />
          {errors.name && <div className="invalid-feedback">
            <p className="warning-icon">This field is required.</p>
          </div>}
          <div className="py-2">
              <label className="form-label">External ID<span className="small"> (optional)</span></label>
              <input className="form-control"
                {...register("external_id",
                  {
                    required: false,
                    onChange: (e: any) => {
                      setValueStrategy("external_id", e.target.value, false)
                    },
                  })
                }
              />
          </div>
          <div className="row py-2">
            <div className="col-6">
              <label className="form-label">EAN<span className="small"> (optional)</span></label>
              <SmartEdit
                instanceValue={props.product.instance?.ean}
                productValue={props.product.ean}
                onValueChanged={(v: any) => setValueStrategy("ean", v)}
                onRestore={(v: any) => restoreValue("ean", props.product.ean)}
                register={register("ean", { required: false })}
              />
            </div>
            <div className="col-6">
              <label className="form-label">UPC<span className="small"> (optional)</span></label>
              <SmartEdit
                instanceValue={props.product.instance?.upc}
                productValue={props.product.upc}
                onValueChanged={(v: any) => setValueStrategy("upc", v)}
                onRestore={(v: any) => restoreValue("upc", props.product.upc)}
                register={register("upc", { required: false })}
              />
            </div>
          </div>
            <div className="py-2">
              <label className="form-label">Region<span className="small"> (optional)</span></label>
              <input className="form-control"
                {...register("region",
                  {
                    required: false,
                    onChange: (e: any) => {
                      setValueStrategy("region", e.target.value, false)
                    },
                  })
                }
              />
            </div>
        </div>
      </div>
      <div className="card my-3">
        <div className="my-3 px-3">
          <label className="form-label">Supplier<span className="small"> (optional)</span></label>
          <SmartEdit
            instanceValue={props.product.instance?.supplier}
            productValue={props.product.supplier?.name}
            onValueChanged={(v: any) => setValueStrategy("supplier", v)}
            onRestore={(v: any) => restoreValue("supplier", props.product.supplier?.name)}
            register={register("supplier", { required: false })}
          />
        </div>
        <div className="px-3">
          <label className="form-label">Brand Family<span className="small"> (optional)</span></label>
          <SmartEdit
            instanceValue={props.product.instance?.brand_family}
            productValue={props.product.brand_family?.name}
            onValueChanged={(v: any) => setValueStrategy("brand_family", v)}
            onRestore={(v: any) => restoreValue("brand_family", props.product.brand_family?.name)}
            register={register("brand_family", { required: false })}
          />
        </div>
        <div className="my-3 px-3">
          <label className="form-label">Brand</label>
          <SmartEdit
            instanceValue={props.product.instance?.brand}
            productValue={props.product.brand?.name}
            onValueChanged={(v: any) => setValueStrategy("brand", v)}
            onRestore={(v: any) => restoreValue("brand", props.product.brand?.name)}
            register={register("brand", { required: false })}
          />
        </div>
      </div>
      <div className="card">
        <div className="my-3 px-3">
          <label className="form-label">Classification<span className="small"> (optional)</span></label>
          <SmartEdit
            instanceValue={props.product.instance?.classification}
            productValue={props.product.classification?.name}
            onValueChanged={(v: any) => setValueStrategy("classification", v)}
            onRestore={(v: any) => restoreValue("classification", props.product.classification?.name)}
            register={register("classification", { required: false })}
          />
        </div>
        <div className="my-3 px-3">
          <label className="form-label">Subclassification<span className="small"> (optional)</span></label>
          <SmartEdit
            instanceValue={props.product.instance?.subclassification}
            productValue={props.product.subclassification?.name}
            onValueChanged={(v: any) => setValueStrategy("subclassification", v)}
            onRestore={(v: any) => restoreValue("subclassification", props.product.subclassification?.name)}
            register={register("subclassification", { required: false })}
          />
        </div>
      </div>
      <div className="card my-3">
        <div className="card-body">
          <div className="row">
            <div className="col-4">
              <label className="form-label">Pack Size<span className="small"> (optional)</span></label>
              <SmartEdit
                instanceValue={props.product.instance?.pack_size}
                productValue={props.product.pack_size}
                onValueChanged={(v: any) => setValueStrategy("pack_size", v)}
                onRestore={(v: any) => restoreValue("pack_size", props.product.pack_size)}
                register={register("pack_size", { required: false })}
              />
            </div>
            <div className="col-4">
              <label className="form-label">Pack Type<span className="small"> (optional)</span></label>
              <SmartEdit
                instanceValue={props.product.instance?.pack_type}
                productValue={props.product.pack_type}
                onValueChanged={(v: any) => setValueStrategy("pack_type", v)}
                onRestore={(v: any) => restoreValue("pack_type", props.product.pack_type)}
                register={register("pack_type", { required: false })}
              />
            </div>
            <div className="col-4">
              <label className="form-label">Size<span className="small"> (optional)</span></label>
              <SmartEdit
                instanceValue={props.product.instance?.size}
                productValue={props.product.size}
                onValueChanged={(v: any) => setValueStrategy("size", v)}
                onRestore={(v: any) => restoreValue("size", props.product.size)}
                register={register("size", { required: false })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card-body my-3">
        {props.product.attributes.length > 0 && (
          <React.Fragment>
            <label className="form-label">Custom Attributes</label>
            <CustomAttributeForm initialData={attributes} onChange={setCustomAttribtues} />
          </React.Fragment>
        )}
      </div>
      <div className="bottom-bar">
        <SaveButton />
      </div>
    </form >
  );
}

export default EditProductInstance;
