import logo from './bbsmall.svg';
import Profile from 'modules/auth0/Profile';
import { NavLink } from 'react-router-dom';
import { featureFlags } from 'configs/featureFlags';

export default function MainMenu() {
  const isActive = (path: string) => {
    const activeItem = window.location.pathname;
    return activeItem === path ? 'active' : '';
  }

  const isResourcesDropdownActive = () => {
    const activeItem = window.location.pathname;
    return activeItem === '/categories' ||
      activeItem === '/brands' ||
      activeItem === '/brand-categories' ||
      activeItem === '/brand-families' ||
      activeItem === '/suppliers' ||
      activeItem === '/classifications' ||
      activeItem === '/subclassifications' ||
      activeItem === '/other-objects' ||
      activeItem === '/labels' ||
      activeItem === '/predefined-classes' ||
      activeItem === '/model-lists'
      ? 'active' : '';
  }

  return(
    <header>
      <nav className="px-3 py-2 navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <div className="d-flex flex-wrap">
            <a href="/products" className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-decoration-none">
              <img src={logo} alt="BrandBank"/>
            </a>
            <ul className="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
              <li className={isActive('/products')}>
                <NavLink to='/products' className="nav-link">Products</NavLink>
              </li>
              <li className={isActive('/customer-product-lists/products')}>
                <NavLink to='/customer-product-lists/products' className="nav-link">Customer Product Lists</NavLink>
              </li>
              <li className={isActive('/product-packshots')}>
                <NavLink to='/product-packshots' className="nav-link">Packshots</NavLink>
              </li>
              <li className={isActive('/predefined-classes')}>
                <NavLink to='/predefined-classes' className="nav-link">Predefined Classes</NavLink>
              </li>
              <li className={`nav-item dropdown ${isResourcesDropdownActive()}`}>
                <a className="nav-link dropdown-toggle" href="#" id="resourcesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Resources
                </a>
                <ul className="dropdown-menu" aria-labelledby="resourcesDropdown">
                  <li className={isActive('/categories')}>
                    <NavLink to='/categories' className="dropdown-item">Categories</NavLink>
                  </li>
                  <li className={isActive('/brands')}>
                    <NavLink to='/brands' className="dropdown-item">Brands</NavLink>
                  </li>
                  <li className={isActive('/brand-categories')}>
                    <NavLink to='/brand-categories' className="dropdown-item">Brand Categories</NavLink>
                  </li>
                  <li className={isActive('/brand-families')}>
                    <NavLink to='/brand-families' className="dropdown-item">Brand Families</NavLink>
                  </li>
                  <li className={isActive('/suppliers')}>
                    <NavLink to='/suppliers' className="dropdown-item">Suppliers</NavLink>
                  </li>
                  <li className={isActive('/classifications')}>
                    <NavLink to='/classifications' className="dropdown-item">Classifications</NavLink>
                  </li>
                  <li className={isActive('/subclassifications')}>
                    <NavLink to='/subclassifications' className="dropdown-item">Subclassifications</NavLink>
                  </li>
                  <li className={isActive('/other-objects')}>
                    <a href='/other-objects' className="dropdown-item">Other Objects</a>
                  </li>
                  <li className={isActive('/labels')}>
                    <NavLink to='/labels' className="dropdown-item">Labels</NavLink>
                  </li>
                  {featureFlags.modelLists && (
                    <li className={isActive('/model-lists')}>
                      <NavLink to='/model-lists' className="dropdown-item">Model Lists</NavLink>
                    </li>
                  )}
                </ul>
              </li>
              <li className={isActive('/imports')}>
                <NavLink to='/imports' className="nav-link">Imports</NavLink>
              </li>
              {featureFlags.exports && (
                <li className={isActive('/exports')}>
                  <NavLink to='/exports' className="nav-link">Exports</NavLink>
                </li>
              )}
            </ul>
          </div>
          <div className="d-flex">
            <Profile />
          </div>
        </div>
      </nav>
    </header>
  );
}
