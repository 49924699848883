import { Skeleton } from "@mui/material";

export const FullPageLoader = () =>
  <>
    <Skeleton variant="rectangular" width={"100%"} height={70} className="rcorners" />
    <p />
    <Skeleton variant="rectangular" width={"100%"} height={50} />
    <p />
    <Skeleton variant="rectangular" width={"100%"} height={50} />
    <p />
    <Skeleton variant="rectangular" width={"100%"} height={50} />
    <p />
    <Skeleton variant="rectangular" width={"100%"} height={50} />
    <p />
    <Skeleton variant="rectangular" width={"100%"} height={50} />
    <p />
    <Skeleton variant="rectangular" width={"100%"} height={50} />
    <p />
    <Skeleton variant="rectangular" width={"100%"} height={50} />
    <p />
    <Skeleton variant="rectangular" width={"100%"} height={50} />
    <p />
    <Skeleton variant="rectangular" width={"100%"} height={50} />
    <p />
    <Skeleton variant="rectangular" width={"100%"} height={50} />
    <p />
    <Skeleton variant="rectangular" width={"100%"} height={50} />
    <p />
    <Skeleton variant="rectangular" width={"100%"} height={50} />

  </>;
